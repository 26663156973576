import { App } from "antd";
import { useState } from "react";
import { updateOrder } from "services";
import { getUserData } from "utils";
import moment from 'moment';
const useAddOrderRemarks = (order_id) => {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [newRemark, setNewRemark] = useState("");
    const handleUpdateRemarks = async (previous_remarks = [], refetch = () => { }, onClose) => {
        const user = getUserData();
        setLoading(true);
        const tempRemarks = (previous_remarks !== "" && typeof previous_remarks === "string") ? JSON.parse(previous_remarks) : [];
        tempRemarks.push({ message: newRemark, type: "service", date: moment().format() });
        updateOrder({
            order_id: order_id || -1,
            value: JSON.stringify(tempRemarks),
            key: "remarks",
            user_id: user.id,
        }).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: "Remarks Updated Successfully!",
                });
                setNewRemark("");
                setLoading(false);
                refetch();
                onClose(false);
            }
        });
    }
    return {
        handleUpdateRemarks,
        loading,
        setNewRemark
    }
}
export default useAddOrderRemarks;