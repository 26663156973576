import { useState } from "react";
import { getAdminReports } from "services";
import { useQuery } from "react-query";
import dayjs from 'dayjs';
const useAdminReports = () => {
    const [dates, setDates] = useState({
        startDate: dayjs().startOf('month'),
        endDate: dayjs(),
    })
    const { isLoading, isError, data, error, refetch } = useQuery({
        queryKey: ["/get-admin-reports", dates],
        queryFn: () => getAdminReports(dates),
        keepPreviousData: false,
    });
    const handleDatesChange = (date) => {
        setDates({
            startDate: date[0],
            endDate: date[1]
        })
    }

    return {
        reports: {
            sales_by_source: data?.sales_by_source || [],
            breakdown_by_region: data?.breakdown_by_region || []
        },
        dates,
        handleDatesChange,
        loading: isLoading
    }
}
export default useAdminReports