import { Button, Modal, Badge, DatePicker } from "antd";
import { FilterOutlined, CloseOutlined } from "@ant-design/icons";
import useSaleFilter from "./useSaleFilter";
const { RangePicker } = DatePicker;
const SaleFilter = () => {
    const {
        isOpen,
        setIsOpen,
        handleRangeChange,
        applyFilter,
        disabled,
        filterApplied,
        handleRemoveFilter
    } = useSaleFilter();
    return (
        <>
            <Badge count={filterApplied ? 1 : 0}>
                <Button onClick={() => setIsOpen(!isOpen)} icon={<FilterOutlined />}>
                    Add Filter
                </Button>
            </Badge>
            {
                filterApplied
                    ?
                    <Button icon={<CloseOutlined />} danger type="dashed" onClick={handleRemoveFilter}>Remove Filter</Button>
                    :
                    null
            }
            <Modal
                title="Filter Orders"
                centered
                open={isOpen}
                onOk={() => { }}
                okText="Filter Orders"
                footer={null}
                width={400}
                onCancel={() => setIsOpen(!isOpen)}
            >
                <div className="d-flex flex-col">
                    <RangePicker style={{ width: "100%", marginTop: 10, }} onChange={handleRangeChange} />
                    <Button
                        disabled={disabled}
                        onClick={applyFilter}
                        style={{ marginTop: 10 }}
                        type="primary" block={true}>
                        Filter Orders
                    </Button>
                </div>
            </Modal>
        </>
    )
}
export default SaleFilter;