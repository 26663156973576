export const TotalBalanceIcon = () => {
    return (
        <>
            <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x={2} y={2} width={36} height={36} rx={18} fill="#CFE7DC" />
                <rect
                    x={2}
                    y={2}
                    width={36}
                    height={36}
                    rx={18}
                    stroke="#E7F4EE"
                    strokeWidth={4}
                />
                <path
                    d="M23 20.5C22.4477 20.5 22 20.9477 22 21.5C22 22.0523 22.4477 22.5 23 22.5H25C25.5523 22.5 26 22.0523 26 21.5C26 20.9477 25.5523 20.5 25 20.5H23Z"
                    fill="#40C979"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28 14.5C29.1046 14.5 30 15.3954 30 16.5V26C30 27.6569 28.6569 29 27 29H13C11.3431 29 10 27.6569 10 26V14C10 12.3431 11.3431 11 13 11H25C26.6569 11 28 12.3431 28 14V14.5ZM25 13H13C12.4477 13 12 13.4477 12 14V26C12 26.5523 12.4477 27 13 27H27C27.5523 27 28 26.5523 28 26V16.5H17C16.4477 16.5 16 16.0523 16 15.5C16 14.9477 16.4477 14.5 17 14.5H26V14C26 13.4477 25.5523 13 25 13Z"
                    fill="#40C979"
                />
            </svg>
        </>
    )
}


export const TotalOrdersIcon = () => {
    return (
        <>
            <svg
                width={41}
                height={40}
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="2.5" y={2} width={36} height={36} rx={18} fill="#FAE1CF" />
                <rect
                    x="2.5"
                    y={2}
                    width={36}
                    height={36}
                    rx={18}
                    stroke="#FDF1E8"
                    strokeWidth={4}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.138 12.1223C14.9569 11.1806 14.1329 10.5 13.174 10.5H11.5C10.9477 10.5 10.5 10.9477 10.5 11.5C10.5 12.0523 10.9477 12.5 11.5 12.5L13.174 12.5L15.05 22.2554C15.4122 24.1388 17.0602 25.5 18.978 25.5H25.1873C27.0044 25.5 28.5932 24.2752 29.0556 22.518L30.3068 17.7635C30.8074 15.8612 29.3726 14 27.4056 14H15.4991L15.138 12.1223ZM15.8837 16L17.014 21.8777C17.1951 22.8194 18.0191 23.5 18.978 23.5H25.1873C26.0959 23.5 26.8903 22.8876 27.1215 22.009L28.3727 17.2545C28.5395 16.6204 28.0613 16 27.4056 16H15.8837Z"
                    fill="#F48031"
                />
                <path
                    d="M17.25 29.5C16.4215 29.5 15.75 28.8284 15.75 28C15.75 27.1716 16.4215 26.5 17.25 26.5C18.0784 26.5 18.75 27.1716 18.75 28C18.75 28.8284 18.0784 29.5 17.25 29.5Z"
                    fill="#F48031"
                />
                <path
                    d="M26 29.5C25.1715 29.5 24.5 28.8284 24.5 28C24.5 27.1716 25.1715 26.5 26 26.5C26.8284 26.5 27.5 27.1716 27.5 28C27.5 28.8284 26.8284 29.5 26 29.5Z"
                    fill="#F48031"
                />
            </svg>

        </>
    )
}



export const OrderSuccessfullIcon = () => {
    return (
        <>
            <svg
                width={40}
                height={40}
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x={2} y={2} width={36} height={36} rx={18} fill="#DEDEFA" />
                <rect
                    x={2}
                    y={2}
                    width={36}
                    height={36}
                    rx={18}
                    stroke="#EFEFFD"
                    strokeWidth={4}
                />
                <g clipPath="url(#clip0_1805_6382)">
                    <path
                        d="M26.951 31.0012H26.918C26.407 30.9932 25.921 30.7762 25.574 30.4012L23.656 28.5202C23.272 28.1232 23.281 27.4902 23.678 27.1062C23.679 27.1052 23.68 27.1042 23.681 27.1032C24.078 26.7222 24.708 26.7332 25.091 27.1272L26.952 28.9502L30.293 25.6092C30.683 25.2192 31.317 25.2192 31.707 25.6092C32.097 25.9992 32.097 26.6332 31.707 27.0232L28.286 30.4442C27.934 30.8012 27.453 31.0022 26.951 31.0012Z"
                        fill="#22CAAD"
                    />
                    <path
                        d="M22.0355 28.0145C22.0315 24.7175 24.7005 22.0415 27.9965 22.0365C29.2795 22.0345 30.5295 22.4465 31.5595 23.2115C33.3325 16.8265 29.5935 10.2135 23.2095 8.44055C16.8255 6.66755 10.2135 10.4055 8.44047 16.7905C6.66747 23.1755 10.4065 29.7895 16.7905 31.5625C18.8905 32.1455 21.1095 32.1455 23.2095 31.5625C22.4475 30.5365 22.0355 29.2925 22.0355 28.0145ZM21.0005 20.0215C21.0005 20.2875 20.8945 20.5415 20.7065 20.7295L17.7015 23.7355C17.3035 24.1195 16.6695 24.1085 16.2855 23.7105C15.9105 23.3225 15.9105 22.7065 16.2855 22.3185L18.9975 19.6065V15.0125C18.9975 14.4595 19.4455 14.0105 19.9995 14.0105C20.5525 14.0105 21.0005 14.4595 21.0005 15.0125V20.0215Z"
                        fill="#22CAAD"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1805_6382">
                        <rect width={24} height={24} fill="white" transform="translate(8 8)" />
                    </clipPath>
                </defs>
            </svg>

        </>
    )
}


export const RewardsPointIcon = () => {
    return (
        <>
            <svg
                width={41}
                height={40}
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect x="2.5" y={2} width={36} height={36} rx={18} fill="#DEDEFA" />
                <rect
                    x="2.5"
                    y={2}
                    width={36}
                    height={36}
                    rx={18}
                    stroke="#EFEFFD"
                    strokeWidth={4}
                />
                <path
                    d="M24.4666 18.4475C24.8519 18.0517 24.8433 17.4186 24.4475 17.0334C24.0517 16.6482 23.4186 16.6568 23.0334 17.0525L19.7737 20.4017L18.3755 19.2809C17.9446 18.9354 17.3152 19.0047 16.9698 19.4356C16.6244 19.8666 16.6936 20.4959 17.1246 20.8414L19.5857 22.8143C19.7867 22.9754 20.0771 22.9575 20.2567 22.7729L24.4666 18.4475Z"
                    fill="#5A6CF8"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.1094 10.3453C19.8847 9.5952 21.1152 9.5952 21.8905 10.3453L23.2193 11.6307L25.0498 11.3718C26.118 11.2207 27.1135 11.944 27.2999 13.0065L27.6193 14.8275L29.2524 15.694C30.2054 16.1996 30.5856 17.3699 30.1119 18.3391L29.2999 20L30.1119 21.661C30.5856 22.6301 30.2054 23.8004 29.2524 24.306L27.6193 25.1725L27.2999 26.9935C27.1135 28.056 26.118 28.7793 25.0498 28.6282L23.2193 28.3693L21.8905 29.6547C21.1152 30.4048 19.8847 30.4048 19.1094 29.6547L17.7806 28.3693L15.95 28.6282C14.8819 28.7793 13.8864 28.056 13.7 26.9935L13.3806 25.1725L11.7475 24.306C10.7945 23.8004 10.4143 22.6301 10.888 21.661L11.6999 20L10.888 18.3391C10.4143 17.3699 10.7945 16.1996 11.7475 15.694L13.3806 14.8275L13.7 13.0065C13.8864 11.944 14.8819 11.2207 15.95 11.3718L17.7806 11.6307L19.1094 10.3453ZM20.4999 11.7827L22.5314 13.7479L25.3299 13.3521L25.8183 16.136L28.3151 17.4607L27.0738 20L28.3151 22.5393L25.8183 23.864L25.3299 26.6479L22.5314 26.2521L20.4999 28.2173L18.4685 26.2521L15.6699 26.6479L15.1816 23.864L12.6848 22.5393L13.9261 20L12.6848 17.4607L15.1816 16.136L15.6699 13.3521L18.4685 13.7479L20.4999 11.7827Z"
                    fill="#5A6CF8"
                />
            </svg>

        </>
    )
}

export const PakistanFlagIcon = ({ width = 25 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="flag-icons-pk"
            viewBox="0 0 640 480"
            width={width}
        >
            <defs>
                <clipPath id="pk-a">
                    <path fillOpacity=".7" d="M-52.3 0h682.6v512H-52.3z" />
                </clipPath>
            </defs>
            <g
                fillRule="evenodd"
                strokeWidth="1pt"
                clipPath="url(#pk-a)"
                transform="translate(49)scale(.9375)"
            >
                <path fill="#0c590b" d="M-95 0h768v512H-95z" />
                <path fill="#fff" d="M-95 0H97.5v512H-95z" />
                <g fill="#fff">
                    <path d="m403.7 225.4-31.2-6.6-16.4 27.3-3.4-31.6-31-7.2 29-13-2.7-31.7 21.4 23.6 29.3-12.4-15.9 27.6 21 24z" />
                    <path d="M415.4 306a121.2 121.2 0 0 1-161.3 59.4 122.1 122.1 0 0 1-59.5-162.1A118.6 118.6 0 0 1 266 139a156.2 156.2 0 0 0-11.8 10.9A112.3 112.3 0 0 0 415.5 306z" />
                </g>
            </g>
        </svg>

    )
}

export const IndiaFlagIcon = ({ width = 25 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="flag-icons-in"
            width={width}
            viewBox="0 0 640 480"
        >
            <path fill="#f93" d="M0 0h640v160H0z" />
            <path fill="#fff" d="M0 160h640v160H0z" />
            <path fill="#128807" d="M0 320h640v160H0z" />
            <g transform="matrix(3.2 0 0 3.2 320 240)">
                <circle r={20} fill="#008" />
                <circle r="17.5" fill="#fff" />
                <circle r="3.5" fill="#008" />
                <g id="in-d">
                    <g id="in-c">
                        <g id="in-b">
                            <g id="in-a" fill="#008">
                                <circle r=".9" transform="rotate(7.5 -8.8 133.5)" />
                                <path d="M0 17.5.6 7 0 2l-.6 5z" />
                            </g>
                            <use
                                xlinkHref="#in-a"
                                width="100%"
                                height="100%"
                                transform="rotate(15)"
                            />
                        </g>
                        <use
                            xlinkHref="#in-b"
                            width="100%"
                            height="100%"
                            transform="rotate(30)"
                        />
                    </g>
                    <use
                        xlinkHref="#in-c"
                        width="100%"
                        height="100%"
                        transform="rotate(60)"
                    />
                </g>
                <use xlinkHref="#in-d" width="100%" height="100%" transform="rotate(120)" />
                <use
                    xlinkHref="#in-d"
                    width="100%"
                    height="100%"
                    transform="rotate(-120)"
                />
            </g>
        </svg>
    )
}
export const UaeFlagIcon = ({ width = 25 }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="flag-icons-ae"
            viewBox="0 0 640 480"
            width={width}
        >
            <path fill="#00732f" d="M0 0h640v160H0z" />
            <path fill="#fff" d="M0 160h640v160H0z" />
            <path fill="#000001" d="M0 320h640v160H0z" />
            <path fill="red" d="M0 0h220v480H0z" />
        </svg>
    )
}