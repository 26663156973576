import axios from "./axios";
const controller_name = "form/";

export const saveForm = (data) => {
    let result = axios
        .post(`${controller_name}save`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const updateForm = (data) => {
    let result = axios
        .post(`${controller_name}update`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};