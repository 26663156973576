import axios from "./axios";
const controller_name = "order/";

export const getSalesUserOrders = (params) => {
    let result = axios
        .get(`${controller_name}get-sales-user-orders`, {
            params: {
                page: params.page,
                limit: params.limit,
                search: params.search,
                filters: params.filters
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};


export const getServiceUserOrders = (params) => {
    let result = axios
        .get(`${controller_name}get-service-user-orders`, {
            params: {
                page: params.page,
                limit: params.limit,
                search: params.search ? params.search.trim() : "",
                filters: params.filters
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};


export const getAllOrders = (params) => {
    let result = axios
        .get(`${controller_name}get`, {
            params: {
                page: params.page,
                limit: params.limit,
                search: params.search,
                filters: params.filters
            }
        })
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};


export const approveOrder = (data) => {
    let result = axios
        .post(`${controller_name}approve-order`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const updateOrder = (data) => {
    let result = axios
        .post(`${controller_name}update`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};



export const upgradeOrder = (order_id) => {
    let result = axios
        .get(`${controller_name}upgrade/${order_id}`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};