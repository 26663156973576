import { useState } from "react";
import { useNavigateInApp } from "hooks";
import { getUserData, getUserRole } from "utils";
const useSidebar = () => {
    const { pathname } = window.location;
    const navigate = useNavigateInApp();
    const userInfo = getUserData();
    const userRole = getUserRole();
    const { profile_image } = userInfo;
    const [activeRoute, setActiveRoute] = useState(pathname);
    const handleRouteChange = (path) => {
        setActiveRoute(path);
        if (path === "/logout") {
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            window.open(window.location.origin, "_self");
        } else {
            navigate(path);
        }
    };
    return {
        profile_image,
        handleRouteChange,
        activeRoute,
        userRole
    }
}
export default useSidebar;