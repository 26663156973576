import { getRegionInformation } from "enums";
import { Avatar, Typography, Tag } from "antd";
const { Text } = Typography;
const UserTile = ({
    assignedUser = {},
    alignment = "column",
    avatarSize = 40,
    userId = null,
    onClick = () => { }
}) => {
    return (
        <>
            <div className="profile-box cursor-pointer">
                {assignedUser?.profile_image === "_defaultUser.png" ? (
                    <Avatar
                        size={avatarSize}
                        style={{
                            backgroundColor: "#f56a00",
                        }}
                    >
                        {assignedUser?.f_name.charAt(0) || "NA"}
                    </Avatar>
                ) : (
                    <Avatar size={avatarSize} src={assignedUser?.profile_image} />
                )}
                <div
                    className="profile-detail-information"
                    style={customStyles[alignment]}
                >
                    <Text>{assignedUser?.f_name + " " + assignedUser?.l_name}</Text>
                    <Tag
                        style={{ maxWidth: "fit-content" }}
                        color={getRegionInformation(assignedUser?.region || 0)?.color}
                    >
                        {getRegionInformation(assignedUser?.region || 0)?.title}
                    </Tag>
                </div>
            </div>
        </>
    );
};
export default UserTile;

const customStyles = {
    column: {
        flexDirection: "column",
        gap: "0rem",
    },
    row: {
        flexDirection: "row",
        gap: "0.5rem",
    },
};
