import { Button, Tooltip, Table } from "antd";
import { ReloadOutlined, } from "@ant-design/icons";
import useCurrencyView from "./useCurrencyView";
import AddCurrencyModal from "components/modals/add-currency";

const CurrencyView = () => {
    const { refresh, tableLoading, records } = useCurrencyView();
    const columns = [
        {
            title: 'Currency Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Conversion Rate Against AED',
            dataIndex: 'conversion_rate',
            key: 'conversion_rate',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'conversion_rate',
            render: (_, record) => {
                return (
                    <AddCurrencyModal callBack={refresh} currency={record} mode="edit" />
                )
            },
        },
    ];
    return (
        <>
            <div className="leads-action-wrapper">
                <div className="leads_action_buttons">
                    <Tooltip placement="bottom" title="Reload">
                        <Button onClick={refresh} icon={<ReloadOutlined />} />
                    </Tooltip>
                </div>
                <div className="leads-action-wrapper-right">
                    <AddCurrencyModal callBack={refresh} />
                </div>
            </div>
            <div className="lead-table">
                <Table
                    columns={columns}
                    rowClassName={(record, index) => index % 2 === 0 ? "table-row-light" : "table-row-dark"}
                    dataSource={records}
                    bordered={true}
                    loading={tableLoading}
                    size="medium"
                    tableLayout="fixed"
                    showHeader={true}
                    scroll={{ x: 1500 }}
                    sticky={true}
                />
            </div>
        </>
    )
}
export default CurrencyView;


