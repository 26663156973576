import { useState } from "react";
import { Form } from 'antd';
import { saveCurrency, updateCurrency } from "services";
const useAddCurrency = () => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState(false);
    const onFinish = (currency, values, cb) => {
        let api = !currency ? saveCurrency : updateCurrency;
        let payload = { ...values };
        if (currency) payload.currency_id = currency.id;
        api(payload).then((result) => {
            if (result.status === 1) {
                form.resetFields();
                cb();
                setModal(false);
            }
        })
    }
    return { modal, setModal, form, onFinish }
}
export default useAddCurrency;