import { Typography, Tag } from "antd";
const { Title, Text } = Typography;
const PaymentSummary = ({ payments = [], size = "medium" }) => {
  const grossRevenue = payments
    .filter((x) => x.payment_status !== 2)
    .reduce((total, payment) => total + payment.payment_amount, 0);

  const deductionAmount = payments
    .filter((x) => x.payment_status === 2)
    .reduce((total, payment) => total + payment.payment_amount, 0);

  const netRevenue = grossRevenue - deductionAmount;

  return (
    <div className={`payment-summary ${size}`}>
      <div className="summary-header">
        <div className="summary-text-container">
          <Text type="secondary">Gross Revenue : </Text>
          <Title level={size === "small" ? 5 : 3}>{grossRevenue.toFixed(2)} AED</Title>
        </div>
        <div className="summary-tags">
          <Tag color="green">
            Net Revenue : {netRevenue ? netRevenue.toFixed(2) : null} AED
          </Tag>
          <Tag color="red">
            Deduction : {deductionAmount ? deductionAmount.toFixed(2) : null}{" "}
            AED
          </Tag>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
