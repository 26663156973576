import { useState, useEffect } from "react";
import { getAllOrders, getAllCurrency } from "services";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUserRole } from "utils";
import { App } from "antd";
import moment from "moment";
const tableColumns = {
    accounts: ["Id", "Name/Email", "Status", "Designation/Company", "Relationship Manager", "Balance Amount", "Summary", "Marketer", "Action"],
    admin: ["Id", "Name/Email", "Designation/Company", "Relationship Manager", "Marketer", "Action"]
}
const useOrderView = () => {
    const { message } = App.useApp();
    const userRole = getUserRole();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currencies, setCurrencies] = useState([]);
    const [showOrderActivity, setShowOrderActivty] = useState(false);
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        limit: searchParams.get("limit") || 15,
        search: searchParams.get("search") || "",
        filters: searchParams.get("search") || "{}",
        total: 0,
    });
    useEffect(() => {
        getAllCurrency().then((result) => {
            if (result.status === 1) {
                setCurrencies(result.data);
            }
        })
    }, [])
    useEffect(() => {
        const hasFilter = searchParams.get("filters");
        if (hasFilter) {
            setParams({ ...params, filters: hasFilter, page: 1 });
        }
    }, [searchParams])
    const { isLoading, isError, data, error, refetch } = useQuery({
        queryKey: ["/get-orders", params],
        queryFn: () => getAllOrders(params),
        keepPreviousData: false,
    });
    const handleSearchParams = (key, value) => {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    };
    const changePage = (page, limit) => {
        handleSearchParams("page", page);
        handleSearchParams("limit", limit);
        setParams({ ...params, page: page, limit: limit });
    };
    const onSearch = (e) => {
        const searchValue = e === "" ? "" : e.target.value;
        handleSearchParams("search", searchValue);
        handleSearchParams("page", 1);
        setParams({ ...params, search: searchValue, page: 1 });
        // refreshData();
    };
    const filterColumns = (data) => {
        const allowedColumns = tableColumns[userRole];
        if (!Array.isArray(data) || data.length === 0 || typeof data[0] !== 'object') {
            return data; // Return data as is if it doesn't match the expected format
        }
        const filteredColumns = [];
        // Filter the columns based on the allowedColumns list
        data.map((row) => {
            const found = allowedColumns.find(x => x === row.title)
            if (found) {
                filteredColumns.push(row)
            }
        });
        return filteredColumns;
    }

    const copyToClipboard = (record) => {
        const arrayToCopy = [];
        if (record?.form?.form_response) {
            const tempParsed = JSON.parse(record.form.form_response);
            const { client_details: { industry, company, designation, name, email, phone, country } } = tempParsed;
            arrayToCopy.push(name);
            arrayToCopy.push(company);
            arrayToCopy.push(designation);
            arrayToCopy.push(email);
            arrayToCopy.push(industry);
            arrayToCopy.push(phone);
            arrayToCopy.push(country);
        } else {
            arrayToCopy.push(record?.lead?.name);
            arrayToCopy.push(record?.lead?.company);
            arrayToCopy.push(record?.lead?.designation);
            arrayToCopy.push(record?.lead?.email);
            arrayToCopy.push("");
            arrayToCopy.push(record?.lead?.phone);
            arrayToCopy.push(record?.lead?.country);
        }
        const rowText = arrayToCopy.join('\t');
        navigator.clipboard.writeText(rowText).then(() => {
            message.open({ type: "success", content: "Row copied to clipboard!" });
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }
    return {
        refresh: () => refetch(),
        refetch: () => refetch(),
        records: data?.data?.data || [],
        total: data?.data?.total,
        currentPageNo: params.page || 1,
        tableLoading: isLoading,
        currencies,
        showOrderActivity,
        setShowOrderActivty,
        changePage,
        onSearch,
        userRole,
        filterColumns,
        pageSize: params.limit || 15,
        copyToClipboard
    }
}
export default useOrderView;

