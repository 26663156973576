import LogoNormal from "assets/images/logo.png";
import { Button, Tooltip, Avatar, Popconfirm } from "antd";
import {
    DollarOutlined,
    ContainerOutlined,
    TeamOutlined,
    AppstoreAddOutlined,
    LogoutOutlined,
    BarChartOutlined,
    RiseOutlined
} from "@ant-design/icons";
import useSidebar from "./useSidebar";
import { allowedRoutes } from "enums";
const Sidebar = () => {
    const { profile_image, handleRouteChange, activeRoute, userRole } = useSidebar();
    return (
        <>
            <div className="left-sidebar">
                <div className="upper-routes">
                    <div
                        onClick={() => handleRouteChange("/")}
                        className="logo-container cursor-pointer"
                    >
                        <img
                            alt="IMC LOGO LEFT ACTION BAR"
                            className="image-cover"
                            src={LogoNormal}
                        />
                    </div>
                    <div className="left-routes">
                        {left_routes.map((route) => (
                            allowedRoutes[userRole].includes(route.path)
                                ?
                                <Tooltip key={`Upper Routes ${route.title}`} placement="right" title={route.title}>
                                    <Button
                                        disabled={route.disabled}
                                        type={route.path === activeRoute ? "primary" : "default"}
                                        onClick={() => handleRouteChange(route.path)}
                                        placement="right"
                                        icon={route.icon}
                                    />
                                </Tooltip>
                                :
                                null
                        ))}
                        {
                            userRole === "service"
                                ?
                                <Tooltip placement="right" title="PMP Students">
                                    <Button
                                        type={activeRoute == "/pmp-students" ? "primary" : "default"}
                                        onClick={() => handleRouteChange("/pmp-students")}
                                        placement="right"
                                        icon={<TeamOutlined />}
                                    />
                                </Tooltip>
                                :
                                null
                        }

                    </div>
                </div>
                <div className="lower-routes">
                    <div className="left-routes">
                        <Popconfirm
                            placement="right"
                            title={"Are you sure you want to sign out"}
                            onConfirm={() => handleRouteChange("/logout")}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button placement="right" icon={<LogoutOutlined />} />
                        </Popconfirm>
                        <div
                            onClick={() => handleRouteChange("/settings")}
                            className="cursor-pointer"
                        >
                            <Avatar
                                src={profile_image == "_defaultUser.png" ? "https://locker.com.au/wp-content/uploads/2017/01/user-icon-png-person-user-profile-icon-20.png" : profile_image}
                                size="medium"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Sidebar;

const left_routes = [
    {
        title: "Dashboard",
        icon: <AppstoreAddOutlined />,
        type: "upper_routes",
        disabled: false,
        path: "/",
    },
    {
        title: "Orders",
        type: "upper_routes",
        path: "/orders",
        icon: <ContainerOutlined />,
        disabled: false,
    },
    {
        title: "Service Reports",
        type: "upper_routes",
        path: "/reports",
        icon: <BarChartOutlined />,
        disabled: false,
    },
    {
        title: "Admin Reports",
        type: "upper_routes",
        path: "/admin-reports",
        icon: <RiseOutlined />,
        disabled: false,
    },
    {
        title: "Currencies",
        type: "upper_routes",
        path: "/currencies",
        icon: <DollarOutlined />,
        disabled: false,
    },
];
