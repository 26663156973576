import axios from "./axios";
const controller_name = "currency/";

export const saveCurrency = (data) => {
    let result = axios
        .post(`${controller_name}save`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const updateCurrency = (data) => {
    let result = axios
        .post(`${controller_name}update`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const getAllCurrency = (data) => {
    let result = axios
        .get(`${controller_name}get`, data)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};