import { useState } from "react";
import { updateOrder } from "services";
import { App } from "antd";
import { getUserData } from "utils";
const useServiceOOC = () => {
    const { message } = App.useApp();
    const [date, setDate] = useState("");
    const [remarks, setRemarks] = useState("");
    const [loading, setLoading] = useState(false);
    const handleUpdateDate = async (order, refetch, onClose) => {
        const user = getUserData();
        setLoading(true);
        const { remarks: previous_remarks } = order;
        const tempRemarks = (previous_remarks !== "" && typeof previous_remarks === "string") ? JSON.parse(previous_remarks) : [];
        tempRemarks.push({ message: remarks, type: "service_onboarding", date: date });
        updateOrder({
            order_id: order?.order_id || -1,
            value: date,
            key: "service_ooc_date",
            user_id: user.id,
            remarks: JSON.stringify(tempRemarks)
        }).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: "Service Orientation Updated Successfully!",
                });
                setLoading(false);
                setRemarks("");
                setDate("");
                refetch();
                onClose(false);
            }
        });
    }

    return { date, setDate, handleUpdateDate, loading, remarks, setRemarks }
}
export default useServiceOOC;