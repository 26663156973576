import { Drawer, Timeline, Spin, Empty } from "antd";
import useOrderTimeline from "./useOrderTimeline";
const OrderTimeline = ({ orderId, onClose }) => {
  const { activityData,isLoading } = useOrderTimeline(orderId);
  return (
    <>
      <Drawer
        title="Order Activity"
        width={500}
        onClose={() => onClose(false)}
        open={orderId ? true : false}
        bodyStyle={{
          paddingBottom: 80,
        }}
      >
        <Spin spinning={isLoading}>
          <Timeline mode="left" items={activityData} />
          {activityData.length === 0 ? <Empty /> : null}
        </Spin>
      </Drawer>
    </>
  );
};
export default OrderTimeline;
