import { Button, Dropdown } from 'antd';
import { MoreOutlined, LockOutlined, MessageOutlined, FolderOutlined, MailOutlined, StarOutlined } from "@ant-design/icons";
import useOrderService from "./useOrderService";
import CourseView from "components/course-view";
import ChangeEmail from "components/modals/change-email";
import AddOrderRemarks from "components/modals/add-order-remarks";
const OrderService = ({ userId = -1, orderId = -1, userType = "service", size = "default", callBack = () => { }, remarks = [] }) => {
    const {
        loading,
        updatePassword,
        sendEmail,
        courseDrawer,
        setCourseDrawer,
        changeEmail,
        setChangeEmail,
        handleUpgradeOrder,
        setRemarksModal,
        remarksModal
    } = useOrderService();
    const items = [
        {
            key: '0',
            label: (
                <Button
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    block
                    onClick={() => setRemarksModal(true)}
                    icon={<FolderOutlined />}
                    type='ghost'
                    size='small'>
                    Remarks
                </Button>
            ),
        },
        {
            key: '1',
            label: (
                <Button
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    block
                    onClick={() => setCourseDrawer("hello")}
                    icon={<FolderOutlined />}
                    type='ghost'
                    size='small'>
                    Courses
                </Button>
            ),
        },
        {
            key: '2',
            label: (
                <Button
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    block
                    onClick={() => updatePassword(userId)}
                    icon={<LockOutlined />}
                    type='ghost'
                    size='small'>
                    Reset Password
                </Button>
            ),
        },
        {
            key: '3',
            label: (
                <Button
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    block
                    onClick={() => sendEmail(userId, orderId)} icon={<MessageOutlined />}
                    type='ghost'
                    size='small'>
                    Send Payment Confirmation Email
                </Button>
            ),
        },
        {
            key: '4',
            label: (
                <Button
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    block
                    onClick={() => setChangeEmail(true)}
                    icon={<MailOutlined />}
                    type='ghost'
                    size='small'>
                    Change Email
                </Button>
            ),
        },
        {
            key: '5',
            label: (
                <Button
                    style={{ display: "flex", justifyContent: "flex-start" }}
                    block
                    onClick={() => handleUpgradeOrder(orderId, callBack)}
                    icon={<StarOutlined />}
                    type='ghost'
                    size='small'>
                    Upgrade Client
                </Button>
            ),
        },
    ];
    return (
        <>
            <AddOrderRemarks
                order_id={orderId}
                remarks={remarks}
                refetch={callBack}
                isOpen={remarksModal}
                onClose={setRemarksModal} />
            {
                courseDrawer
                    ?
                    <CourseView
                        order_id={orderId}
                        user_id={userId}
                        isOpen={courseDrawer}
                        onClose={setCourseDrawer} />
                    :
                    null
            }
            <ChangeEmail
                orderId={orderId}
                userId={userId}
                isOpen={changeEmail}
                onClose={setChangeEmail}
            />
            <Dropdown menu={{ items: items.filter(x => filteredOptions[userType].includes(x.key)) }} placement="bottomRight" arrow disabled={loading}>
                <Button size={size} loading={loading} icon={<MoreOutlined />} />
            </Dropdown>
        </>
    )
}
export default OrderService;

const filteredOptions = {
    user: ["2", "3", "5"],
    service: ["0", "1", "2", "3", "4"]
}