import { useState } from "react";
import { updateLmsUser } from "services";
import { App } from "antd";
const useChangeEmail = () => {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [inputError, setInputError] = useState(false);
    const updateLMSEmail = (user_id, orderId, callBack = () => { }) => {
        const isValid = email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (isValid) {
            setLoading(true);
            const params = {
                key: "email",
                value: email,
                id: user_id,
                order_id: orderId
            };
            updateLmsUser(params).then((result) => {
                if (result.status === 1) {
                    message.open({
                        type: "success",
                        content: result.message,
                    });
                } else {
                    message.open({
                        type: "error",
                        content: "Email Already Registered With IMC",
                    });
                }
                setLoading(false);
                callBack();
            })
        } else {
            setInputError(true);
        }
    }
    return { updateLMSEmail, loading, inputError, setEmail }
}
export default useChangeEmail;