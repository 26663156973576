import { useState } from "react";
const usePaymentDetails = () => {
    const [error, setError] = useState(false);
    const verifyTotalAmount = (values, currencies) => {
        // const { payment_currency, paid_amount, total_revenue_aed } = values;
        // const selectedCurrency = currencies.find((x) => x.name === payment_currency);
        // const afterConversion = (paid_amount * parseFloat(selectedCurrency.conversion_rate)).toFixed(2);
        // if (parseInt(afterConversion) > parseInt(total_revenue_aed) - 10) {
        //     setError(true);
        //     return false;
        // }
        // setError(false);
        return true;
    }
    return { error, verifyTotalAmount, setError }
}
export default usePaymentDetails;