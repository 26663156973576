import {
    Button,
    Tooltip,
    Input,
    Typography,
    Table,
    Avatar,
    Tag,
    Space,
    Badge,
} from "antd";
import { ReloadOutlined, SwapOutlined } from "@ant-design/icons";
import usePmpStudents from "./usePmpStudents";
import moment from "moment";
import ChangeApplicationStatus from "components/modals/change-application-status";
import AddRemarks from "components/modals/add-remarks";
import { getCustomerRegion, getPmpApplicationStatus } from "enums";
const { Search } = Input;
const { Text } = Typography;
const PmpStudentsView = () => {
    const { onSearch, onRefresh, searchValue, tableLoading, records } = usePmpStudents();
    const columns = [
        {
            title: "Order Date",
            dataIndex: "order_date",
            key: "order_date",
            render: (_, record) => {
                const date = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <>
                        {/* {getOrderUserStatus(record.login_generated)}<br /> */}
                        <Tooltip placement="bottom" title={date}>
                            <Text>{date}</Text>
                        </Tooltip>
                    </>
                )
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {
                return (
                    <>
                        <Text>
                            {record?.lead?.name}<br />
                            {getCustomerRegion(record?.lead?.region)}
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Phone/Email",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {

                return (

                    <>
                        <Text>
                            {record?.lead?.phone}
                            <br />
                            {record?.lead?.email}
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Payment Status",
            dataIndex: "order_status",
            key: "order_status",
            render: (_, record) => {
                return (
                    <>
                        {
                            record?.payment_status === 1
                                ?
                                <Badge status="success" text="Fully Paid" />
                                :
                                <Badge status="processing" text="Partial Paid" />
                        }
                    </>
                );
            },
        },
        {
            title: "Application Status",
            dataIndex: "order_status",
            key: "order_status",
            render: (_, record) => {
                return (
                    <>
                        <Space>
                            {getPmpApplicationStatus(record.application_status)}
                            <ChangeApplicationStatus defaultValue={record.application_status}/>
                        </Space>
                    </>
                );
            },
        },
        {
            title: "Membership",
            dataIndex: "membership",
            key: "membership",
            render: (_, record) => {
                return (
                    <>
                        {getPmpApplicationStatus(record.application_status)}
                    </>
                );
            },
        },
        {
            title: "Action",
            key: "action",
            // fixed: 'right',
            // ellipsis: true,
            render: (_, record) => {
                const orderDate = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <Space>
                        <AddRemarks remarks={record?.remarks}/>
                    </Space>
                )
            },
        },
    ];
    return (
        <>
            <div className="leads-action-wrapper">
                <div className="leads_action_buttons">
                    <Tooltip placement="bottom" title="Reload">
                        <Button onClick={onRefresh} icon={<ReloadOutlined />} >Refresh</Button>
                    </Tooltip>
                </div>
                <div className="leads-action-wrapper-right">
                    <Search
                        placeholder="input search text"
                        onSearch={onSearch}
                        allowClear
                        onPressEnter={onSearch}
                        loading={false}
                        defaultValue={searchValue}
                        style={{ maxWidth: 400 }}
                    />
                    {/* <SaleFilter /> */}
                </div>
            </div>
            <div className="lead-table">
                <Table
                    columns={columns}
                    rowClassName={(_, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    dataSource={records}
                    bordered={true}
                    loading={tableLoading}
                    size="medium"
                    tableLayout="fixed"
                    showHeader={true}
                    pagination={{
                        position: ["none", "bottomCenter"],
                        // total: total,
                        hideOnSinglePage: true,
                        // current: parseInt(currentPageNo),
                        showLessItems: true,
                        onChange: (page, pageSize) => {
                            window.scrollTo(0, 0);
                            // changePage(page, pageSize);
                        },
                    }}
                    scroll={{ x: 1500 }}
                    sticky={true}
                />
            </div>
        </>
    )
}
export default PmpStudentsView;