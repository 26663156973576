import axios from "./axios";
const controller_name = "user/";
export const getAllUser = () => {
    let result = axios
        .get(`${controller_name}get`)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};