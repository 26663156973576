import { useState } from "react";
import { savePayment } from "services";
import { App } from "antd";
const usePaymentDrawer = () => {
    const { message } = App.useApp();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [paymentCurrency, setPaymentCurrency] = useState('{"name":"AED","conversion_rate":1}'); // Making AED By default
    const [activeTab, setActiveTab] = useState("payment");
    const handleSavePayment = (values, order_id, cb) => {
        setLoading(true);
        const parsedPaymentCurrency = JSON.parse(paymentCurrency);
        const { payment_method, payment_amount, remarks } = values;
        const payload = {
            payment_currency: parsedPaymentCurrency.name,
            payment_amount: parseFloat(payment_amount) * parsedPaymentCurrency.conversion_rate,
            payment_method,
            remarks,
            order_id,
            payment_status: activeTab === "payment" ? 0 : 2,
        };
        savePayment(payload).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: result.message,
                });
                cb();
            }
            setLoading(false);
        })
    }
    return {
        open,
        setOpen,
        setActiveTab,
        activeTab,
        handleSavePayment,
        setPaymentCurrency,
        loading
    }
}
export default usePaymentDrawer;