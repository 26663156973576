import { useState, useEffect } from "react";
import { getAllUser } from "services";
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
const useOrderFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isOpen, setIsOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [relationshipManagers, setRelationShipManagers] = useState([]);
    const [region, setRegion] = useState([]);
    const [rangePicker, setRangePicker] = useState([]);
    const [marketers, setMarketers] = useState([]);
    const [platform, setPlatforms] = useState([]);
    const [filterApplied, setFilterApplied] = useState(0);
    useEffect(() => {
        getAllUser().then((result) => {
            if (result.status === 1) {
                setUsers(result.data);
            }
        });
    }, []);
    const handleChange = (propName, propValue) => {
        if (propName === "relationshipManagers") {
            let payload = [];
            propValue.forEach((item) => {
                const filteredUser = users.find((x) => x.f_name === item);
                payload.push(filteredUser.id);
            })
            setRelationShipManagers(payload);
        } else if (propName === "region") {
            setRegion(propValue);
        } else if (propName === "range") {
            setRangePicker(propValue);
        }
        else if (propName === "platform") {
            setPlatforms(propValue);
        } else {
            setMarketers(propValue);
        }
    };
    const handleOnFilterLeads = () => {
        let filter = {};
        let filterApplied = 0;
        let rm = [];
        let re = [];
        let mt = [];
        let pf = [];
        if (relationshipManagers.length > 0) {
            rm = relationshipManagers.join(",");
            filter.relationshipManagers = rm;
            filterApplied++;
        }
        if (region.length > 0) {
            re = region.join(",");
            filter.region = re;
            filterApplied++;
        }

        if (marketers.length > 0) {
            mt = marketers.join(",");
            filter.marketers = mt;
            filterApplied++;
        }
        if (platform.length > 0) {
            pf = platform.join(",");
            filter.platforms = pf;
            filterApplied++;
        }
        if (rangePicker.length > 0) {
            if (rangePicker[0] !== "" && rangePicker[1] !== "") {
                // filter.range = rangePicker.join(",");
                filter.range = rangePicker.map((item) => dayjs(item).format("YYYY-MM-DD")).join(",");
                filterApplied++;
            }
        }
        setFilterApplied(filterApplied);
        const stringifyFilter = JSON.stringify(filter);
        searchParams.set("filters", stringifyFilter);
        setSearchParams(searchParams);
        setIsOpen(!isOpen);
    };
    const handleRemoveFilter = () => {
        searchParams.set("filters", JSON.stringify({}));
        setSearchParams(searchParams);
        setFilterApplied(0);
        setIsOpen(false);
    }
    return { isOpen, setIsOpen, filterApplied, handleRemoveFilter, users, handleChange, handleOnFilterLeads }
}
export default useOrderFilter;