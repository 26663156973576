import {
    Drawer,
    Select,
    Table,
    Divider,
    Typography,
    Button,
    Popconfirm,
    Space,
  } from "antd";
  import { DeleteOutlined } from "@ant-design/icons";
  import useCourseView from "./useCourseView";
  import moment from "moment";
  const { Text } = Typography;
  const CourseView = ({ isOpen, onClose, order_id, user_id, user_email }) => {
    const {
      getUniqueCourses,
      handleEnrolledCourse,
      enrolled_courses,
      enrolled_courses_loading,
      handleRemoveEnrolledCourse,
    } = useCourseView(order_id, user_id, user_email);
    const columns = [
      {
        title: "Title",
        dataIndex: "name",
        key: "id",
        width: 250,
        ellipse:true,
        render: (_, record) => (
          <>
            <Text>{record?.course?.title}</Text>
          </>
        ),
      },
      {
        title: "Enrolled On",
        dataIndex: "enrolled_at",
        width: 120,
        render: (_, record) => (
          <>
            <Text>{moment(record?.enrolled_at).format("MMMM Do YYYY")}</Text>
          </>
        ),
      },
      {
        title: "Action",
        dataIndex: "action",
        width: 120,
        key: "action",
        render: (_, record) => (
          <>
            <Space>
              <Popconfirm
                disabled={record.certificates.length > 0}
                title="Remove Course from User"
                description="Are you sure to remove the course from user?"
                onConfirm={() => handleRemoveEnrolledCourse(record.id)}
                okText="Yes"
                cancelText="No"
              >
                <Button disabled={record.certificates.length > 0} size="small" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          </>
        ),
      },
    ];

    return (
      <>
        <Drawer
          title="Courses"
          width={720}
          onClose={() => onClose(false)}
          open={isOpen}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <Select
            value=""
            placeholder="Add a new course"
            onChange={(value) => handleEnrolledCourse(value)}
            style={{
              width: "100%",
            }}
            showSearch
            // filterOption={(input, option) =>
            // (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            // }
            options={[
              {
                label: <span>Combo Courses</span>,
                title: "Combo Courses",
                options: combo_courses_enum
              },
              {
                label: <span>All Courses</span>,
                title: "All Courses",
                options: getUniqueCourses()
              }
            ]}
  
          />
          <Divider>Enrolled Courses</Divider>
          <Table
            pagination={false}
            columns={columns}
            dataSource={enrolled_courses}
            loading={enrolled_courses_loading}
            scroll={{ x: 600 }}
            sticky={true}
            size="small"
            tableLayout="fixed"
            showHeader={true}
          />
        </Drawer>
      </>
    );
  };
  export default CourseView;
  
  
  const combo_courses_enum = [
    {
      value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }]),
      label: "GB + BB",
    },
    {
      value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 10, courseTitle: "PMP Certification Training" }]),
      label: "GB + PMP",
    },
    {
      value: JSON.stringify([{ courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }, { courseId: 10, courseTitle: "PMP Certification Training" }]),
      label: "BB + PMP",
    },
    {
      value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }, { courseId: 10, courseTitle: "PMP Certification Training" }]),
      label: "GB + BB + PMP",
    },
    {
      value: JSON.stringify([{ courseId: 14, courseTitle: "Certified Lean Six Sigma Green Belt " }, { courseId: 13, courseTitle: "Certified Lean Six Sigma Black Belt" }, { courseId: 12, courseTitle: "Lean Six Sigma Master Black Belt" }]),
      label: "GB + BB + MBB",
    },
    {
      value: JSON.stringify([{ courseId: 87, courseTitle: "Certified Supply Chain Foundation" }, { courseId: 97, courseTitle: "Certified Supply Chain Specialist" }]),
      label: "CSCF + CSCS",
    },
    {
      value: JSON.stringify([{ courseId: 87, courseTitle: "Certified Supply Chain Foundation" }, { courseId: 98, courseTitle: "Certified Supply Chain Expert" }, { courseId: 97, courseTitle: "Certified Supply Chain Specialist" }]),
      label: "CSCF + CSCS + CSCE",
    },
  ]