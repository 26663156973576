import { Button, Form, Select, Alert } from 'antd';
import { payment_methods } from "enums";
import { InputNumber } from "antd";
import usePaymentDetails from "./usePaymentDetails";
const { Option } = Select;
const PaymentDetails = ({ onFinish = () => { }, defaultData = {}, currencies }) => {
    const [form] = Form.useForm();
    const { error, verifyTotalAmount, setError } = usePaymentDetails();
    const { paid_amount, payment_currency, payment_method, payment_type, total_revenue, total_revenue_aed } = defaultData;
    return (
        <>
            {error ?
                <Alert
                    afterClose={() => setError(false)}
                    closable
                    message="Please check paid amount"
                    type="error"
                    showIcon /> : null}
            <Form
                form={form}
                onFinish={(values) => verifyTotalAmount(values, currencies) ? onFinish(values) : null}
                layout="vertical"
            >
                <Form.Item
                    rules={[{ required: true, message: 'Please enter total revenue in aed!', },]}
                    label="Total Revenue In AED"
                    name="total_revenue_aed"
                    required
                    initialValue={total_revenue_aed}
                    tooltip="Enter total amount of revenue in AED">
                    <InputNumber style={{ width: "100%" }} placeholder="Enter Total Revenue in AED" />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: 'Please enter total revenue in local!', },]}
                    label="Total Revenue In Local Currency"
                    name="total_revenue"
                    required
                    initialValue={total_revenue}
                    tooltip="This is a required field">
                    <InputNumber style={{ width: "100%" }} placeholder="Enter Total Revenue in Local Currency" />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: 'Please enter paid amount!', }]}
                    label="Paid Amount In Local Currency"
                    name="paid_amount"
                    required
                    initialValue={paid_amount}
                    tooltip="Enter amount in charged currency">
                    <InputNumber style={{ width: "100%" }} placeholder="Enter Paid Amount" />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: 'Please select currency!', },]}
                    label="Payment Currency"
                    name="payment_currency"
                    initialValue={payment_currency}
                    required
                    tooltip="This is a required field">
                    <Select
                        placeholder="Please select currency"
                        options={currencies.map((item) => ({ label: item.name, value: item.name }))}
                    />
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: 'Please select method!', },]}
                    label="Payment Method"
                    name="payment_method"
                    required
                    initialValue={payment_method}
                    tooltip="This is a required field">
                    <Select placeholder="Please select payment method">
                        {payment_methods
                            .sort(function (a, b) {
                                if (a.title < b.title) {
                                    return -1;
                                }
                                if (a.title > b.title) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((gateway) => (
                                <Option
                                    key={`Payment Gateways ${gateway.title}`}
                                    value={gateway.title}
                                >
                                    {gateway.title}
                                </Option>
                            ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{ required: true, message: 'Please select payment type!', },]}
                    label="Payment Type"
                    name="payment_type"
                    initialValue={payment_type}
                    required
                    tooltip="This is a required field">
                    <Select options={payment_types} placeholder="Please select payment type" />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" block type="primary">Next Step</Button>
                </Form.Item>
            </Form>
        </>
    )
}
export default PaymentDetails;


const payment_types = [
    { label: "Registration", value: "registration" },
    { label: "Full Payment", value: "full" },
    { label: "2 Months Installments", value: "2 months installments" },
    { label: "3 Months Installments", value: "3 months installments" },
    { label: "Tabby 4 Months", value: "tabby 4 months" },
    { label: "Cashew 3 Months", value: "cashew 3 months" },
    { label: "Tamara 3 Months", value: "tamara 3 months" },
]