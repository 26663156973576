import { Button, Tooltip, Drawer, Divider, Tabs, Col, Row, Form, Select, Input, Spin } from "antd";
import { DollarOutlined } from "@ant-design/icons";
import PaymentSummary from "components/payment-summary";
import usePaymentDrawer from "./usePaymentDrawer";
import PaymentView from "components/payment-view";
import { payment_methods } from "enums";
const { Option } = Select;
const PaymentDrawer = ({ payments = [], orderId = -1, disabled = false, currencies = [], callBack = () => { },size="medium" }) => {
    const [form] = Form.useForm();
    const {
        open,
        setOpen,
        setActiveTab,
        activeTab,
        handleSavePayment,
        setPaymentCurrency,
        loading
    } = usePaymentDrawer();
    return (
        <>
            <Tooltip placement="bottom" title="Payments">
                <Button
                    disabled={disabled}
                    onClick={() => setOpen(true)}
                    icon={<DollarOutlined />}
                    size={size}
                />
            </Tooltip>
            {
                open
                    ?
                    <Drawer
                        title="Payments"
                        placement="right"
                        closable={false}
                        width={720}
                        onClose={() => setOpen(false)}
                        open={open}
                        bodyStyle={{
                            paddingBottom: 80,
                        }}
                    >
                        <PaymentSummary payments={payments} />
                        <Spin spinning={loading} tip="Loading...">
                            <Divider />
                            <Tabs
                                defaultActiveKey="1"
                                items={tabList}
                                onChange={(key) => setActiveTab(key)}
                            />
                            <Form
                                form={form}
                                layout="vertical"
                                requiredMark={false}
                                onFinish={(values) => handleSavePayment(values, orderId, () => { form.resetFields(); callBack(); })}
                            >
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="payment_amount"
                                            label="Paid Amount"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter paid amount",
                                                },
                                            ]}
                                        >
                                            <Input
                                                min={1}
                                                // max={isOpen.sale_amount}
                                                type="number"
                                                addonAfter={
                                                    <Select
                                                        defaultValue="AED"
                                                        onChange={(value) => setPaymentCurrency(value)}
                                                        style={{
                                                            width: 70,
                                                        }}
                                                    >
                                                        {
                                                            currencies && currencies.map((item, index) => (
                                                                <Option key={`Currencies ${index}`} value={JSON.stringify({ name: item.name, conversion_rate: item.conversion_rate })}>{item.name}</Option>
                                                            ))
                                                        }
                                                    </Select>}
                                                placeholder="Please enter paid amount"
                                            />
                                        </Form.Item>
                                    </Col>
                                    {activeTab === "payment" ? (
                                        <Col span={12}>
                                            <Form.Item
                                                name="payment_method"
                                                label="Payment Method"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please enter payment Method",
                                                    },
                                                ]}
                                            >
                                                <Select placeholder="Please select an method">
                                                    {payment_methods
                                                        .sort(function (a, b) {
                                                            if (a.title < b.title) {
                                                                return -1;
                                                            }
                                                            if (a.title > b.title) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        })
                                                        .map((gateway) => (
                                                            <Option
                                                                key={`Payment Gateways ${gateway.title}`}
                                                                value={gateway.title}
                                                            >
                                                                {gateway.title}
                                                            </Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="remarks"
                                            label="Notes"
                                            rules={[
                                                {
                                                    required: false,
                                                    message: "please enter remarks",
                                                },
                                            ]}
                                        >
                                            <Input.TextArea rows={2} placeholder="Please enter remarks" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Save
                                    </Button>
                                </Form.Item>
                            </Form>
                            {activeTab === "payment" ? (
                                <>
                                    <Divider>Payments</Divider>
                                    <PaymentView
                                        orderId={orderId}
                                        payments={payments.filter((x) => x.payment_status !== 2)}
                                        mode={activeTab}
                                    />
                                </>
                            ) : (
                                <>
                                    <Divider>Deductions</Divider>
                                    <PaymentView
                                        orderId={orderId}
                                        payments={payments.filter((x) => x.payment_status === 2)}
                                        mode={activeTab}
                                    />
                                </>
                            )}
                        </Spin>
                    </Drawer>
                    :
                    null
            }
        </>
    )
}

export default PaymentDrawer;

const tabList = [
    {
        key: "payment",
        label: "Payments",
        children: "",
    },
    {
        key: "deducation",
        label: "Deductions",
        children: "",
    },
];