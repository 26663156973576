import { Button, Descriptions, Typography, Alert, Input } from 'antd';
import useClientDetails from "./useClientDetails";
const { Text } = Typography;
const ClientDetails = ({ lead = {}, orderDate, onClick = () => { }, defaultData = {} }) => {
    const { country, designation, phone } = lead;
    const {
        newEmail,
        setNewEmail,
        newName,
        setNewName,
        handleSubmit,
        error,
        industry,
        setIndustry,
        newCompany,
        setNewCompany,
        newDesignation,
        setNewDesignation,
        whatsApp,
        setWhatsApp
    } = useClientDetails(lead, defaultData);
    const items = [
        {
            key: '1',
            label: 'Order Date',
            span: 3,
            children: orderDate,
        },
        {
            key: '2',
            label: <Text>Name<span className='required-marks'>*</span></Text>,
            span: 3,
            children: <Text strong type="danger" editable={{ onChange: (value) => setNewName(value) }}>{newName}</Text>,
        },
        {
            key: '3',
            label: <Text>Email<span className='required-marks'>*</span></Text>,
            span: 4,
            children: <Text strong type="danger" editable={{ onChange: (value) => setNewEmail(value) }}>{newEmail}</Text>
        },
        {
            key: '4',
            label: 'Phone',
            span: 1,
            children: phone,
        },
        {
            key: '2',
            label: <Text>WhatsApp<span className='required-marks'>*</span></Text>,
            span: 2,
            children: <Text strong type="danger" editable={{ onChange: (value) => setWhatsApp(value) }}>{whatsApp}</Text>,
        },
        {
            key: '5',
            label: <Text>Company<span className='required-marks'>*</span></Text>,
            children: <Text editable={{ onChange: (value) => setNewCompany(value) }}>{newCompany}</Text>

        },
        {
            key: '6',
            span: 2,
            label: <Text>Designation<span className='required-marks'>*</span></Text>,
            children: <Text editable={{ onChange: (value) => setNewDesignation(value) }}>{newDesignation}</Text>
        },
        {
            key: '7',
            label: <Text>Country<span className='required-marks'>*</span></Text>,
            children: country,
        },
        {
            key: '7',
            label: <Text>Industry<span className='required-marks'>*</span></Text>,
            children: <Text editable={{ onChange: (value) => setIndustry(value) }}>{industry}</Text>
            // children: <Input value={industry} onChange={(e) => setIndustry(e.target.value)} placeholder='Enter Client Industry' />,
        },

    ];
    return (
        <>
            {error !== "" ? <Alert message={error} type="error" showIcon /> : null}
            <Descriptions bordered items={items} />
            <Button onClick={() => handleSubmit(onClick)} type="primary">Next Step</Button>
        </>
    )
}
export default ClientDetails;