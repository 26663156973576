import { useState } from "react";
import { updateLmsUser, sendCredientialsEmail, upgradeOrder } from "services";
import { App } from "antd";
const useOrderService = () => {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const [remarksModal, setRemarksModal] = useState(false);
    const [courseDrawer, setCourseDrawer] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);
    const updatePassword = (user_id) => {
        setLoading(true);
        const params = {
            key: "password",
            value: "$2a$12$mLQFybsUAvrbWr5melpU0e8vDDWEPZZ9/hYOj0Ju9/39V8E.SQniW",
            id: user_id
        };
        updateLmsUser(params).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: result.message,
                });
            }
            setLoading(false);
        })
    }
    const sendEmail = (user_id, order_id) => {
        setLoading(true);
        sendCredientialsEmail({ userId: user_id, orderId: order_id }).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: result.message,
                });
            }
            setLoading(false);
        })
    }

    const handleUpgradeOrder = (order_id, callback = () => { }) => {
        setLoading(true);
        upgradeOrder(order_id).then((result) => {
            if (result.status === 1) {
                message.open({
                    type: "success",
                    content: result.message,
                });
                callback();
            }
            setLoading(false);
        })
    }

    return {
        loading,
        updatePassword,
        sendEmail,
        courseDrawer,
        setCourseDrawer,
        changeEmail,
        setChangeEmail,
        handleUpgradeOrder,
        setRemarksModal,
        remarksModal
    }
}
export default useOrderService;