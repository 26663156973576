import { Button, Modal, Space, DatePicker, Spin, Input, Timeline, Divider } from 'antd';
import useServiceOOC from "./useServiceOOC";
import moment from 'moment';
const { TextArea } = Input;
const ServiceOOC = ({ isOpen = false, onClose = () => { }, refresh = () => { } }) => {
    const { remarks } = isOpen;
    const { setDate, date, handleUpdateDate, loading, setRemarks } = useServiceOOC();
    return (
        <>
            <Modal
                title="Service Orientation Call"
                centered
                open={isOpen?.order_id || false}
                footer={null}
                onCancel={() => onClose(false)}
                width={300}
            >
                <Spin spinning={loading}>
                    <Space size={15} direction="vertical" style={{ width: "100%" }}>
                        <DatePicker style={{ width: "100%" }} onChange={(_, dateString) => setDate(dateString)} />
                        <TextArea onChange={(e) => setRemarks(e.target.value)} placeholder='Remarks will go here' rows={4} />
                        <Button onClick={() => handleUpdateDate(isOpen, refresh, onClose)} disabled={date === ""} block type='primary'>Save</Button>

                        {
                            remarks !== "" && typeof remarks === "string"
                                ?
                                <>
                                    <Divider />
                                    <Timeline
                                        items={JSON.parse(remarks).filter((x) => x.type === "service_onboarding").map((item) => ({ children: `${item.message} - ${moment(item.date).format("MMMM Do YYYY")}` }))}
                                    />
                                </>
                                :
                                null
                        }
                    </Space>
                </Spin>
            </Modal>
        </>
    )
}
export default ServiceOOC;