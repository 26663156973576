import { Button, Tooltip, Input, Typography, Table, Space, Tag } from "antd";
import { ReloadOutlined, PhoneOutlined, CopyOutlined, CheckCircleOutlined, AimOutlined } from "@ant-design/icons";
import useServiceOrderView from "./useServiceOrderView";
import ViewOrderModal from "components/modals/view-order";
import OrderService from "components/order-service";
import UserTile from "components/user-tile";
import moment from "moment";
import OrderPaymentStatus from "components/order-payment-status";
import { getOrderUserStatus, getServiceStatus } from "enums";
import ServiceOOC from "components/modals/service-ooc";
import ServiceOrderFilter from "components/service-filter";
const { Search } = Input;
const { Text } = Typography;
const ServiceOrdersView = () => {
    const { onSearch,
        refreshData,
        records,
        total,
        tableLoading,
        currentPageNo,
        changePage,
        searchValue,
        pageSize,
        setServiceOrienModal,
        serviceOrienModal,
        copyToClipboard,
        refetch
    } = useServiceOrderView();
    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            ellipsis: true,
            key: "id",
            render: (_, record) => {
                const date = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <>
                        <div className="d-flex flex-col">
                            <Text>{record.id}</Text>
                            <Tooltip placement="bottom" title={date}>
                                <Text>{date}</Text>
                            </Tooltip>
                        </div>
                    </>
                )
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {
                return (
                    <>
                        <Text>
                            {record?.lead?.name}<br />
                            {getOrderUserStatus(record.login_generated)}
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Phone/Email",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {

                return (

                    <>
                        <Text>
                            {record?.lead?.phone}
                            <br />
                            {record?.lead?.email}
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Relationship Manager",
            dataIndex: "sale_amount",
            key: "sale_amount",
            width: 180,
            render: (_, record) => (
                <>
                    {record?.relationship_manager ? (
                        <UserTile userId={record?.sold_by || null} assignedUser={record?.relationship_manager || {}} />
                    ) : (
                        <Text>Not Assgined to any user</Text>
                    )}
                </>
            ),
        },
        {
            title: "Service Call",
            dataIndex: "service_ooc",
            key: "service_ooc",
            render: (_, record) => {
                const { region } = record.lead;
                return (
                    <>
                        <div className="d-flex gap-1 align-items-center">
                            {getServiceStatus(record.service_ooc, record.service_ooc_date, region)}
                            {
                                region === 1
                                    ?
                                    <Button onClick={() => setServiceOrienModal({ order_id: record.id, remarks: record.remarks })} icon={<PhoneOutlined />} />
                                    :
                                    null
                            }
                        </div>
                    </>
                );
            },
        },
        {
            title: "Revenue",
            dataIndex: "sale_amount",
            ellipsis: true,
            width: 120,
            key: "sale_amount",
            render: (_, record) => {
                return (
                    <>
                        <Text>
                            {record?.sale_amount} AED
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Balance",
            dataIndex: "payment_status",
            key: "payment_status",
            width: 160,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <OrderPaymentStatus record={record} />
                </>
            ),
        },
        {
            title: "Email",
            dataIndex: "email_sent",
            key: "email_sent",
            render: (_, record) => {
                return (
                    <>
                        <Tag color={record.email_sent ? "success" : "error"}>{record.email_sent ? "Email Sent" : "Email Pending"}</Tag>
                    </>
                );
            },
        },
        {
            title: "Action",
            key: "action",
            // fixed: 'right',
            // ellipsis: true,
            render: (_, record) => {
                const orderDate = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <Space>
                        <Tooltip placement="bottom" title={getCoursesSummary(record?.enrolls || [], record?.certifications || [], record.id)}>
                            <Button size="small">Summary</Button>
                        </Tooltip>
                        <Tooltip placement="bottom" title="Copy for Excel">
                            <Button size="small" onClick={() => copyToClipboard(record)} icon={<CopyOutlined />} />
                        </Tooltip>
                        {
                            record.form
                                ?
                                <ViewOrderModal size="small" showPayment={false} form={JSON.parse(record.form.form_response)} orderDate={orderDate} />
                                :
                                null
                        }
                        {record.lms_user_id ? <OrderService
                            remarks={record.remarks}
                            size="small"
                            userType="service"
                            orderId={record.id}
                            userId={record.lms_user_id}
                        /> : null}
                    </Space>
                )
            },
        },
    ];

    return (
        <>
            <ServiceOOC refresh={refetch} isOpen={serviceOrienModal} onClose={setServiceOrienModal} />
            <div className="leads-action-wrapper">
                <div className="leads_action_buttons">
                    <Tooltip placement="bottom" title="Reload">
                        <Button onClick={refreshData} icon={<ReloadOutlined />} >Refresh</Button>
                    </Tooltip>
                </div>
                <div className="leads-action-wrapper-right">
                    <Search
                        placeholder="input search text"
                        onSearch={onSearch}
                        allowClear
                        onPressEnter={onSearch}
                        loading={false}
                        defaultValue={searchValue}
                        style={{ maxWidth: 400 }}
                    />
                    <ServiceOrderFilter />
                </div>
            </div>
            <div className="lead-table">
                <Table
                    columns={columns}
                    rowClassName={(_, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    dataSource={records}
                    bordered={true}
                    loading={tableLoading}
                    size="small"
                    tableLayout="fixed"
                    showHeader={true}
                    pagination={{
                        position: ["none", "bottomCenter"],
                        total: total,
                        hideOnSinglePage: true,
                        pageSize: pageSize,
                        current: parseInt(currentPageNo),
                        showLessItems: true,
                        onChange: (page, pageSize) => {
                            window.scrollTo(0, 0);
                            changePage(page, pageSize);
                        },
                    }}
                    scroll={{ x: 1500 }}
                    sticky={true}
                />
            </div>
        </>
    )
}
export default ServiceOrdersView;


const getCoursesSummary = (enrolledCourses = [], certifications = [], order_id) => {
    return (
        <div className="course-summary">
            {enrolledCourses.map((enroll, index) => (
                <div key={`Enrolled Courses ${index}`} className="course-list">
                    <Text style={{ color: "white" }}>{enroll?.course.title}</Text>
                    <CheckCircleOutlined style={{ color: getCircleStyle(enroll, certifications) }} />
                    {enroll.order_id === order_id ? <AimOutlined style={{ color: "#52c41a" }} /> : null}
                </div>
            ))
            }
        </div>
    )
}


const getCircleStyle = (enrolled, certifications = []) => {
    let color = "white";
    if (!enrolled) return color;
    const filterCertificate = certifications.filter(x => x.course_id === enrolled.course_id);
    if (filterCertificate.length > 0) {
        color = "green";
        return color;
    }
    return color;
}