
import useOrderForm from "./useOrderform";
import { Button, Modal, Steps, Spin } from 'antd';
import { SmileOutlined, SolutionOutlined, UserOutlined, DollarOutlined } from '@ant-design/icons';
import ClientDetails from "./client-details";
import PaymentDetails from "./payment-details";
import CoursesDetails from "./course-details";
import ServiceDetails from "./service-details";
const OrderFormModal = ({ lead = {}, orderDate, orderId, mode = "add", form = {}, currencies = [], callBack = () => { } }) => {
    const { modal, setModal, step, handleSteps } = useOrderForm(orderId, form);
    // const { form_response } = form;
    return (
        <>
            <Button type="primary" onClick={() => setModal(true)}>
                {mode === "edit" ? "Edit " : ""} Form
            </Button>
            {
                modal
                    ?
                    <Modal
                        title="Payment Form"
                        centered
                        open={modal}
                        footer={null}
                        onCancel={() => setModal(false)}
                        width={700}
                    >
                        <div className="payment-form">
                            <Steps
                                current={step}
                                items={[
                                    { title: 'Client Details', icon: <UserOutlined /> },
                                    { title: 'Payment', icon: <DollarOutlined /> },
                                    { title: 'Courses', icon: <SolutionOutlined /> },
                                    { title: 'Service', icon: <SmileOutlined /> },
                                ]}
                            />
                            {step === 0 ?
                                <ClientDetails
                                    defaultData={form?.form_response ? JSON.parse(form.form_response).client_details : {}}
                                    onClick={(values) => handleSteps("client_details", values)}
                                    orderDate={orderDate}
                                    lead={lead} />
                                :
                                null}
                            {step === 1 ?
                                <PaymentDetails
                                    currencies={currencies}
                                    defaultData={form?.form_response ? JSON.parse(form.form_response).payment_details : {}}
                                    onFinish={(values) => handleSteps("payment_details", values)} />
                                :
                                null
                            }
                            {
                                step === 2 ?
                                    <CoursesDetails
                                        defaultData={form?.form_response ? JSON.parse(form.form_response).course_details : {}}
                                        onClick={(values) => handleSteps("course_details", values)} />
                                    :
                                    null
                            }
                            {
                                step === 3 ?
                                    <ServiceDetails
                                        defaultData={form?.form_response ? JSON.parse(form.form_response).service_details : []}
                                        onFinish={(values) => handleSteps("service_details", values, callBack)} orderDate={orderDate} lead={lead} />
                                    : null
                            }
                            {step === 4 ? <Spin tip="Saving Form" size="large"><div style={contentStyle} /></Spin> : null}
                        </div>
                    </Modal>
                    :
                    null
            }
        </>
    )
}

export default OrderFormModal;





const contentStyle = {
    padding: 50,
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 4,
};