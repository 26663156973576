import { Button, Tooltip, Table, Typography, Space, Popconfirm } from "antd";
import moment from "moment";
import { getPaymentStatus } from "enums";
import { MessageOutlined } from "@ant-design/icons";
// import usePaymentView from "./usePaymentView";
// import EditPayment from "../edit-payment";
const { Text } = Typography;
const PaymentView = ({ orderId, lmsUserId, payments, mode }) => {
  //   const { handleUpdatePayment } = usePaymentView(orderId, lmsUserId);
  const handleUpdatePayment = () => { }
  const columns = [
    {
      title: "Paid Amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
      render: (_, record) => (
        <>
          <Text>{record.payment_amount + " AED"}</Text>
        </>
      ),
    },
    {
      title: "Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (_, record) => (
        <>
          <Text>{moment(record.payment_date).format("MMMM Do YYYY")}</Text>
        </>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      render: (_, record) => <>{getPaymentStatus(record.payment_status)}</>,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          {/* <EditPayment payment={record} /> */}
          <Tooltip
            placement="bottom"
            title={record.remarks ? record.remarks : "No Remarks Available"}
          >
            <Button size="small" icon={<MessageOutlined />} />
          </Tooltip>
          {record.payment_status !== 1 ? (
            <Popconfirm
              title="Refund Payment"
              description="Are you sure to refund the payment?"
              onConfirm={() =>
                handleUpdatePayment(1, "payment_status", record.id)
              }
              okText="Yes"
              cancelText="No"
            >
              <Button size="small" danger>
                Refund
              </Button>
            </Popconfirm>
          ) : null}
        </Space>
      ),
    },
  ];

  const deductionColumns = [
    {
      title: "Paid Amount",
      dataIndex: "payment_amount",
      key: "payment_amount",
      render: (_, record) => (
        <>
          <Text>{record.payment_amount + " AED"}</Text>
        </>
      ),
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      render: (_, record) => (
        <>
          <Text>{moment(record.payment_date).format("MMMM Do YYYY")}</Text>
        </>
      ),
    },
    {
      title: "Desciption",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];
  return (
    <>
      <Table
        columns={mode === "payment" ? columns : deductionColumns}
        dataSource={payments}
        pagination={false}
      />
    </>
  );
};

export default PaymentView;
