// import { useState } from "react";
import {
    getAllCourses,
    saveEnrolledCourse,
    getUserEnrolledCourses,
    removeUserEnrolledCourse,
    saveCertificateAgainstCourse,
  } from "services";
  import { App } from "antd";
  import { useQuery } from "react-query";
  const useCourseView = (order_id, user_id, user_email) => {
    const { message } = App.useApp();
    const { isError, data } = useQuery({
      queryKey: ["/get-all-courses"],
      queryFn: () => getAllCourses(),
      keepPreviousData: true,
    });
    const {
      isLoading: enrolledCoursesLoading,
      isError: enrolledCoursesError,
      data: enrolledCourses,
      refetch,
    } = useQuery({
      queryKey: ["/get-user-enrolled-courses"],
      queryFn: () => getUserEnrolledCourses({ order_id, user_id }),
      keepPreviousData: false,
    });
    if (isError || enrolledCoursesError) {
      message.open({
        type: "error",
        content: "Something went wrong, please try again later!",
      });
    }
    const getUniqueCourses = () => {
      const select_options = [];
      data?.data.map((item) => {
        if (item.id !== 11) {
          const found = enrolledCourses?.data.find(
            (result) => result.course_id == item.id
          );
          if (!found) {
            select_options.push({
              value: JSON.stringify({ id: item.id, title: item.title }),
              label: item.title,
            });
          }
        }
      });
      return select_options;
    };
  
    const handleEnrolledCourse = (value) => {
      const parsedData = JSON.parse(value);
      if (Array.isArray(parsedData)) {
        parsedData.forEach((course, index) => {
          const alreadyEnrolled = enrolledCourses?.data.filter(x => x.course_id === course.courseId);
          if (alreadyEnrolled.length === 0) {
            saveEnrolledCourse({
              user_id,
              order_id,
              course_id: course.courseId,
              user_email,
              course_title: course.courseTitle,
            }).then((result) => {
              if (result.status === 1) {
                if (parsedData.length - 1 === index) refetch();
              }
            });
          }
        })
      } else {
        saveEnrolledCourse({
          user_id,
          order_id,
          course_id: parsedData.id,
          user_email,
          course_title: parsedData.title,
        }).then((result) => {
          if (result.status === 1) {
            refetch();
          }
        });
      }
    };
  
    const handleRemoveEnrolledCourse = (enrolled_course_id) => {
      removeUserEnrolledCourse(enrolled_course_id).then((result) => {
        if (result.status === 1) {
          refetch();
        }
      });
    };
  
    const handleAddCertificate = (enrolled_course) => {
      saveCertificateAgainstCourse({
        course_id: enrolled_course.course_id,
        user_id: enrolled_course.user_id,
        course_name: enrolled_course.course.title,
      }).then((result) => {
        if (result.status === 1) {
          refetch();
        }
      });
    };
  
    return {
      all_courses: data?.data || [],
      enrolled_courses: enrolledCourses?.data || [],
      enrolled_courses_loading: enrolledCoursesLoading,
      getUniqueCourses,
      handleEnrolledCourse,
      handleRemoveEnrolledCourse,
      handleAddCertificate,
    };
  };
  export default useCourseView;
  