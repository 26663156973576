import { getAllCurrency } from "services";
import { useQuery } from "react-query";
const useCurrencyView = () => {
    const { isLoading, data, refetch } = useQuery({
        queryKey: ["/get-currency"],
        queryFn: () => getAllCurrency(),
        keepPreviousData: false,
    });
    return {
        refresh: () => refetch(),
        tableLoading: isLoading,
        records: data?.data || [],
        total: data?.data?.total,
    }
}
export default useCurrencyView;