import { useState, useEffect } from "react";
import { getSalesUserOrders, getAllCurrency } from "services";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
const useSaleOrderView = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [currencies, setCurrencies] = useState([]);
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        limit: searchParams.get("limit") || 15,
        search: searchParams.get("search") || "",
        filters: searchParams.get("search") || "{}",
        total: 0,
    });
    const { isLoading, isError, data, error, refetch } = useQuery({
        queryKey: ["/order-sales-users-view", params],
        queryFn: () => getSalesUserOrders(params),
        keepPreviousData: false,
    });
    useEffect(() => {
        getAllCurrency().then((result) => {
            if (result.status === 1) {
                setCurrencies(result.data);
            }
        })
    }, [])
    useEffect(() => {
        const hasFilter = searchParams.get("filters");
        if (hasFilter) {
            setParams({ ...params, filters: hasFilter });
        }
    }, [searchParams])
    const handleSearchParams = (key, value) => {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    };

    const changePage = (page, limit) => {
        handleSearchParams("page", page);
        handleSearchParams("limit", limit);
        setParams({ ...params, page: page, limit: limit });
    };
    const onSearch = (e) => {
        const searchValue = e === "" ? "" : e.target.value;
        handleSearchParams("search", searchValue);
        handleSearchParams("page", 1);
        setParams({ ...params, search: searchValue, page: 1 });
    };
    const refreshData = () => {
        searchParams.set("page", 1);
        searchParams.set("limit", 10);
        searchParams.set("search", "");
        searchParams.set("filters", "{}");
        setSearchParams(searchParams);
        setParams({
            page: 1,
            limit: 10,
            search: "",
            filters: "{}",
            total: 0
        })
    }
    return {
        refreshData,
        refetch,
        records: data?.data?.data || [],
        total: data?.data?.total,
        tableLoading: isLoading,
        currencies,
        currentPageNo: params.page || 1,
        changePage,
        onSearch,
        searchValue: params.search || ""
    }
}
export default useSaleOrderView;