const records = [
    {
        "id": 6937,
        "application_status":0,
        "lead_id": 210089,
        "lms_user_id": 16407,
        "payment_status":1,
        "sold_by": 114,
        "order_status": 0,
        "order_date": "2024-06-12",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 272,
        "conversion_rate": null,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "AED",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-12T06:26:19.000Z",
        "updatedAt": "2024-06-12T06:26:48.000Z",
        "lead": {
            "id": 210089,
            "name": "Fouad Qaid Abdu Hassan",
            "email": "fouad.hassan@yemen.net.ye",
            "phone": "0777011764",
            "company": "NA",
            "designation": "NA",
            "country": "Yemen",
            "interested_in": "Website Payment",
            "status": "won",
            "assign_to": "114",
            "lead_cost": null,
            "lead_source": "",
            "campaign_for": "YB",
            "cost_currency": null,
            "lead_quality": null,
            "description": null,
            "region": 1,
            "manager": 0,
            "marketer": "NA",
            "assigned_on": "2024-06-12T06:26:11.000Z",
            "created": "2024-06-12T06:25:21.000Z"
        },
        "key": 6937,
        "remarks":"Remarks will go here"
    },
    {
        "id": 6936,
        "application_status":1,
        "lead_id": 206526,
        "lms_user_id": 16408,
        "sold_by": 132,
        "order_status": 0,
        "order_date": "2024-06-12",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 1427,
        "conversion_rate": 75,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "PKR",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-12T05:58:39.000Z",
        "updatedAt": "2024-06-12T05:59:04.000Z",
        "lead": {
            "id": 206526,
            "name": "Zeeshan Alizai",
            "email": "neon661@gmail.com",
            "phone": "+923054647976",
            "company": "Abacus consulting",
            "designation": "Manager",
            "country": "PK",
            "interested_in": "PK_SSPMP0106PN",
            "status": "won",
            "assign_to": "132",
            "lead_cost": "238.1",
            "lead_source": "ig",
            "campaign_for": "SS+PMP",
            "cost_currency": "PKR",
            "lead_quality": null,
            "description": null,
            "region": 3,
            "manager": 0,
            "marketer": "priya",
            "assigned_on": "2024-06-07T06:52:35.000Z",
            "created": "2024-06-04T20:02:43.000Z"
        },
        "key": 6936,
        "remarks":"Remarks will go here"
    },
    {
        "application_status":2,
        "id": 6934,
        "lead_id": 192156,
        "lms_user_id": 16414,
        "sold_by": 47,
        "order_status": 0,
        "order_date": "2024-06-12",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "payment_status":1,
        "sale_amount": 4731,
        "conversion_rate": null,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "AED",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-12T04:52:24.000Z",
        "updatedAt": "2024-06-12T06:20:36.000Z",
        "lead": {
            "id": 192156,
            "name": "Salaheddin",
            "email": "szaamout@hotmail.com",
            "phone": "+966 55 422 6422",
            "company": "Asite",
            "designation": "MEA Professional Service Manager",
            "country": "Saudi Arabia",
            "interested_in": "ME_SSPMP0223E3",
            "status": "won",
            "assign_to": "47",
            "lead_cost": "45.63",
            "lead_source": "LinkedIn",
            "campaign_for": "SS+PMP",
            "cost_currency": "AED",
            "lead_quality": null,
            "description": null,
            "region": 1,
            "manager": 0,
            "marketer": "Khalid",
            "assigned_on": "2024-05-06T11:42:56.000Z",
            "created": "2024-05-04T13:32:16.000Z"
        },
        "key": 6934,
        "remarks":"Remarks will go here"
    },
    {
        "id": 6933,
        "application_status":4,
        "lead_id": 207034,
        "lms_user_id": 16415,
        "sold_by": 206,
        "order_status": 0,
        "order_date": "2024-06-12",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 4749,
        "conversion_rate": null,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "AED",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-12T04:52:13.000Z",
        "updatedAt": "2024-06-12T06:25:12.000Z",
        "lead": {
            "id": 207034,
            "name": "Krishna Guru",
            "email": "gskrishna11@gmail.com",
            "phone": "+971504356087",
            "company": "Health Care",
            "designation": "Medical Records Administration",
            "country": "AE",
            "interested_in": "ME_SSPMP_3105PN",
            "status": "won",
            "assign_to": "206",
            "lead_cost": "23.8",
            "lead_source": "fb",
            "campaign_for": "SS+PMP",
            "cost_currency": "AED",
            "lead_quality": null,
            "description": null,
            "region": 1,
            "manager": 0,
            "marketer": "priya",
            "assigned_on": "2024-06-06T07:50:10.000Z",
            "created": "2024-06-05T21:08:10.000Z"
        },
        "key": 6933
    },
    {
        "id": 6932,
        "application_status":5,
        "lead_id": 187168,
        "lms_user_id": 16409,
        "sold_by": 29,
        "order_status": 0,
        "order_date": "2024-06-12",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "payment_status":1,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 5040,
        "conversion_rate": null,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "AED",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-12T04:13:49.000Z",
        "updatedAt": "2024-06-12T05:59:53.000Z",
        "lead": {
            "id": 187168,
            "name": "Kai",
            "email": "kai.kocher@interpublic.com",
            "phone": "0522138819",
            "company": "MCN",
            "designation": "Director IT Transformation",
            "country": "AE",
            "interested_in": "GB + BB + PMP",
            "status": "won",
            "assign_to": "29",
            "lead_cost": null,
            "lead_source": "Web Lead",
            "campaign_for": null,
            "cost_currency": null,
            "lead_quality": null,
            "description": null,
            "region": 1,
            "manager": 0,
            "marketer": "",
            "assigned_on": "2024-04-25T07:29:53.000Z",
            "created": "2024-04-24T18:52:28.000Z"
        },
        "key": 6932
    },
    {
        "id": 6930,
        "lead_id": 204755,
        "application_status":6,
        "lms_user_id": 16413,
        "sold_by": 224,
        "order_status": 0,
        "order_date": "2024-06-11",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 3000,
        "conversion_rate": null,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "AED",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-11T18:18:51.000Z",
        "updatedAt": "2024-06-12T06:19:44.000Z",
        "lead": {
            "id": 204755,
            "name": "Saldevir Lou",
            "email": "sld_pena@yahoo.com",
            "phone": "+971 55 363 3588",
            "company": "Private Company",
            "designation": "Warehouse & Inventory Manager",
            "country": "United Arab Emirates",
            "interested_in": "ME_SC0160401KH_B",
            "status": "won",
            "assign_to": "224",
            "lead_cost": "12.5",
            "lead_source": "LinkedIn",
            "campaign_for": "SC",
            "cost_currency": "AED",
            "lead_quality": null,
            "description": null,
            "region": 1,
            "manager": 0,
            "marketer": "khalid",
            "assigned_on": "2024-05-31T08:36:30.000Z",
            "created": "2024-05-30T18:09:26.000Z"
        },
        "key": 6930
    },
    {
        "id": 6929,
        "application_status":7,
        "lead_id": 209420,
        "lms_user_id": 16412,
        "sold_by": 224,
        "order_status": 0,
        "order_date": "2024-06-11",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "payment_status":1,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 4211,
        "conversion_rate": null,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "AED",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-11T15:19:34.000Z",
        "updatedAt": "2024-06-12T06:18:49.000Z",
        "lead": {
            "id": 209420,
            "name": "dr elham albuaijan",
            "email": "eal.boaija@gmail.com",
            "phone": "+966504817332",
            "company": "Ministry of health",
            "designation": "Dentist",
            "country": "Saudi Arabia",
            "interested_in": "MESS+PMP0706P3",
            "status": "won",
            "assign_to": "224",
            "lead_cost": null,
            "lead_source": "ig",
            "campaign_for": "SS+PMP",
            "cost_currency": null,
            "lead_quality": null,
            "description": null,
            "region": 1,
            "manager": 0,
            "marketer": "priya",
            "assigned_on": "2024-06-11T12:11:22.000Z",
            "created": "2024-06-11T10:51:38.000Z"
        },
        "form": null,
        "key": 6929
    },
    {
        "id": 6926,
        "lead_id": 140958,
        "application_status":8,
        "lms_user_id": 16410,
        "sold_by": 110,
        "order_status": 0,
        "order_date": "2024-06-11",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 3507,
        "conversion_rate": null,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "AED",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-11T14:14:04.000Z",
        "updatedAt": "2024-06-12T06:00:36.000Z",
        "lead": {
            "id": 140958,
            "name": "Jenni Santos",
            "email": "jennilynsantos618@gmail.com",
            "phone": "+971551784518",
            "company": "PIPMC",
            "designation": "civil engineer",
            "country": "AE",
            "interested_in": "SS_PRC0901ME",
            "status": "won",
            "assign_to": "110",
            "lead_cost": null,
            "lead_source": "fb",
            "campaign_for": "SS+ISO",
            "cost_currency": null,
            "lead_quality": null,
            "description": null,
            "region": 1,
            "manager": 0,
            "marketer": "priya",
            "assigned_on": "2024-01-12T11:52:32.000Z",
            "created": "2024-01-12T08:55:43.000Z"
        },
        "key": 6926
    },
    {
        "id": 6924,
        "application_status":11,
        "lead_id": 158076,
        "lms_user_id": 16405,
        "sold_by": 247,
        "order_status": 0,
        "order_date": "2024-06-11",
        "candidate_courses": null,
        "candidate_mol": null,
        "batch_no": null,
        "login_generated": true,
        "accounts_verification": false,
        "service_ooc": false,
        "service_ooc_date": null,
        "sale_amount": 1000,
        "conversion_rate": 75,
        "sale_amount_in_aed": null,
        "sale_amount_currency": "PKR",
        "service_verified": false,
        "total_certificates": null,
        "certificates_issued": null,
        "on_hold": false,
        "on_hold_reason": null,
        "createdAt": "2024-06-11T11:53:05.000Z",
        "updatedAt": "2024-06-11T12:26:13.000Z",
        "lead": {
            "id": 158076,
            "name": "Abdu",
            "email": "rehman7766@gmail.com",
            "phone": "+92 333 7766395",
            "company": "National Development Consultants (Pvt) Ltd (NDC)",
            "designation": "Assistant Resident Engineer",
            "country": "Saudi Arabia",
            "interested_in": "ME_SSPMPKH21102E1",
            "status": "won",
            "assign_to": "247",
            "lead_cost": null,
            "lead_source": "LinkedIn",
            "campaign_for": "SS+PMP",
            "cost_currency": null,
            "lead_quality": null,
            "description": null,
            "region": 3,
            "manager": 0,
            "marketer": "khalid",
            "assigned_on": "2024-06-11T06:48:40.000Z",
            "created": "2024-02-21T11:34:14.000Z"
        },
        "key": 6924
    }
]
const usePmpStudents = () => {
    return {records}
}
export default usePmpStudents;


