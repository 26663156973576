import useAdminOrderView from "./useAdminOrderView";
import {
    Button,
    Tooltip,
    Input,
    Typography,
    Space,
    Table
} from "antd";
import { ReloadOutlined, ProfileOutlined, } from "@ant-design/icons";
import PaymentDrawer from "components/payment-drawer";
import { marketers, getOrderPaymentStatus } from "enums";
import moment from "moment";
import ViewOrderModal from "components/modals/view-order";
import OrderTimeline from "components/order-timeline";
import OrderFilter from "components/order-filter";
const { Search } = Input;
const { Text } = Typography;
const AdminOrderView = () => {
    const {
        changePage,
        records,
        onSearch,
        total,
        tableLoading,
        currencies,
        showOrderActivity,
        currentPageNo,
        setShowOrderActivty,
        userRole,
        refetch,
        pageSize,
    } = useAdminOrderView();
    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            // width: 100,
            key: "id",
        },
        {
            title: "Date",
            dataIndex: "order_date",
            key: "order_date",
            render: (_, record) => {
                const date = moment(record.order_date).format("ddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <>
                        <Text>{date}</Text>
                    </>
                )
            },

        },
        {
            title: "Name",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {
                return (<Text> {record?.lead?.name} </Text>)
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            ellipsis: true,
            key: "email",
            render: (_, record) => {
                return (<Text> {record?.lead?.email} </Text>)
            },
        },
        {
            title: "Designation",
            dataIndex: "designation",
            ellipsis: true,
            key: "designation",
            render: (_, record) => {
                return (<Text> {record?.lead?.designation} </Text>)
            },
        },
        {
            title: "Company",
            dataIndex: "company",
            key: "company",
            ellipsis: true,
            render: (_, record) => {
                return (<Text> {record?.lead?.company} </Text>)
            },
        },
        {
            title: "RM",
            dataIndex: "rm",
            key: "rm",
            ellipsis: true,
            render: (_, record) => {
                const { relationship_manager } = record;
                return (<Text> {(relationship_manager?.f_name + " " + relationship_manager?.l_name) || "NA"} </Text>)
            },
        },
        {
            title: "Revenue",
            dataIndex: "revenue",
            key: "revenue",
            ellipsis: true,
            render: (_, record) => {
                const { sale_amount } = record;
                return ( sale_amount ? <Text> {sale_amount || 0} AED</Text> : null )
            },
        },
        {
            title: "Balance Amount",
            dataIndex: "payment_status",
            key: "payment_status",
            width: 160,
            ellipsis: true,
            render: (_, record) => (
                <>
                    {record.sale_amount ? `${(record.sale_amount - getOrderPaymentStatus(record.sale_amount, record.payments).totalPaidAmount).toFixed(0)} AED` : null}
                </>
            ),
        },
        {
            title: "Marketer",
            dataIndex: "rm",
            key: "rm",
            ellipsis: true,
            render: (_, record) => {
                const { marketer } = record?.lead;
                const selectedMarketer = marketers[marketer];
                return (<Text>{selectedMarketer?.title || "NA"}</Text>)
            },
        },
        {
            title: "Action",
            key: "action",
            // ellipsis: true,
            render: (_, record) => {
                const orderDate = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <Space>

                        {
                            record.form
                                ?
                                <>
                                    <ViewOrderModal
                                        callBack={refetch}
                                        formId={record.form.id}
                                        size="small"
                                        orderId={record.id}
                                        upgradedOrder={record.upgraded_order}
                                        counsellor={record?.relationship_manager?.email}
                                        enrolledAt={record?.relationship_manager?.region === "3" ? 25 : 35}
                                        isApproved={record.accounts_verification}
                                        isAdmin={true}
                                        form={JSON.parse(record.form.form_response)}
                                        orderDate={orderDate} />
                                </>
                                :
                                null
                        }
                        <PaymentDrawer
                            disabled={!record.sale_amount}
                            payments={record.payments}
                            currencies={currencies}
                            size="small"
                            orderId={record.id}
                            callBack={refetch}
                        />
                        {
                            userRole === "admin"
                                ?
                                <Tooltip placement="bottom" title="Activity">
                                    <Button
                                        onClick={() => setShowOrderActivty(record.id)}
                                        size="small"
                                        icon={<ProfileOutlined />}
                                    />
                                </Tooltip>
                                :
                                null
                        }
                    </Space>
                )
            },
        },
    ];
    return (
        <>
            {showOrderActivity && userRole === "admin" ? (
                <OrderTimeline
                    orderId={showOrderActivity}
                    onClose={setShowOrderActivty}
                />
            ) : null}
            <div className="leads-action-wrapper">
                <div className="leads_action_buttons">
                    <Tooltip placement="bottom" title="Reload">
                        <Button onClick={refetch} icon={<ReloadOutlined />} />
                    </Tooltip>
                </div>
                <div className="leads-action-wrapper-right">
                    <Search
                        placeholder="input search text"
                        onSearch={onSearch}
                        allowClear
                        onPressEnter={onSearch}
                        loading={false}
                        style={{
                            maxWidth: 400,
                        }}
                    />
                    <OrderFilter />
                </div>
            </div>
            <div className="lead-table">
                <Table
                    columns={columns}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    dataSource={records}
                    bordered={true}
                    loading={tableLoading}
                    size="small"
                    // tableLayout="fixed"
                    showHeader={true}
                    // rowSelection={true}
                    pagination={{
                        position: ["none", "bottomCenter"],
                        total: total,
                        hideOnSinglePage: true,
                        pageSize: pageSize,
                        current: parseInt(currentPageNo),
                        showLessItems: true,
                        onChange: (page, pageSize) => {
                            window.scrollTo(0, 0);
                            changePage(page, pageSize);
                        },
                    }}
                    scroll={{ x: 1500 }}
                    sticky={true}
                />
            </div>
        </>
    )
}
export default AdminOrderView;