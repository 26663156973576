import { useMemo, useState } from "react";
import { getServiceReports } from "services";
import { useQuery } from "react-query";
import dayjs from 'dayjs';
const useServiceDashboard = () => {
    const [dates, setDates] = useState({
        startDate: dayjs().startOf('month'),
        endDate: dayjs(),
    })
    const { isLoading, isError, data, error, refetch } = useQuery({
        queryKey: ["/get-service-dashboard", dates],
        queryFn: () => getServiceReports(dates),
        keepPreviousData: false,
    });
    const handleDatesChange = (date) => {
        setDates({
            startDate: date[0],
            endDate: date[1]
        })
    }
    return {
        refresh: () => refetch(),
        certificates: data?.data?.certificates || [],
        totalCertificatesReleases: useMemo(() => (((data?.data?.certificates || []).reduce((total, item) => total + item.certification_count, 0)))),
        certificatesIssuedMonthly: data?.data?.certificates_issued_monthly || [],
        totalEnrolledUsers: data?.data?.total_enrolled_users || 0,
        pendingServiceCall: data?.data?.pending_service_call || 0,
        totalCertificatesIsseuedYearly: useMemo(() => (((data?.data?.certificates_issued_monthly || []).reduce((total, item) => total + item.total_certificates, 0))), [data]),
        loading: isLoading,
        dates,
        handleDatesChange
    }
}
export default useServiceDashboard;