import { Modal, Image, Descriptions } from 'antd';
import EmailBanner from "assets/images/email-banner.png";
const EmailPreview = ({ isOpen = false, onClose, paymentDetails }) => {
    const { paid_amount, payment_currency, payment_method, payment_type, total_revenue } = paymentDetails;
    const items = [
        {
            key: '1',
            label: 'Total Revenue',
            children: `${total_revenue} ${payment_currency}`,
            labelStyle: labelStyle
        },
        {
            key: '2',
            label: 'Amount Paid',
            children: `${paid_amount} ${payment_currency}`,
            labelStyle: labelStyle
        },
        {
            key: '10',
            span: 3
        },
        {
            key: '3',
            label: 'Remaining Balance',
            children: `${total_revenue-paid_amount} ${payment_currency}`,
            labelStyle: labelStyle
        },
        {
            key: '4',
            label: 'Payment Method',
            labelStyle: labelStyle,
            children: `${payment_method}`,
        },
    ];
    return (
        <>
            <Modal
                title="Email Preview"
                centered
                open={isOpen}
                footer={null}
                onCancel={() => onClose(false)}
                width={700}
            >
                <div className='email-preview-container'>
                    <Image preview={false} src={EmailBanner} />
                    <div className='payment-details'>
                        <h4 className='email-button'> Payment Details</h4>
                        <Descriptions size="small" layout="vertical" items={items} />
                    </div>
                </div>
            </Modal>
        </>
    )
}
export default EmailPreview;

const labelStyle = {
    color: "black",
    fontWeight: "bold",
}