import axios from "./axios";
const controller_name = "lms-users/";

export const updateLmsUser = (params) => {
    let result = axios
        .get(`${controller_name}update-lms-user`, { params })
        .then((res) => {
            return res?.data || [];
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};


export const sendCredientialsEmail = (params) => {
    let result = axios
        .get(`${controller_name}send-email`, {
            params
        })
        .then((res) => {
            return res?.data || [];
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

