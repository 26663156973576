import { Button, Modal, Input, Space, Tooltip } from 'antd';
import useAddRemarks from "./useAddRemarks";
const { TextArea } = Input;
const AddRemarks = ({ remarks }) => {
    const { modal, setModal } = useAddRemarks();
    return (
        <>
            <Tooltip placement="bottom" title={remarks}>
                <Button onClick={() => setModal(true)} size="small" >Remarks</Button>
            </Tooltip>
            {
                modal
                    ?
                    <Modal
                        title="Add Remarks"
                        centered
                        open={modal}
                        footer={null}
                        onCancel={() => setModal(false)}
                        width={300}
                    >
                        <Space size={10} direction="vertical" style={{ width: "100%" }}>
                            <TextArea placeholder='Add Remarks here' rows={4} />
                            <Button block type='primary'>Save Remark</Button>
                        </Space>
                    </Modal>
                    :
                    null
            }
        </>
    )
}
export default AddRemarks;