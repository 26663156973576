import { Col, Row, Card, Statistic, List, Typography, Spin, Flex, Badge, Button, DatePicker } from 'antd';
// import { Liquid } from '@ant-design/plots';
import { ReloadOutlined, UsergroupAddOutlined, } from '@ant-design/icons';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";
import useServiceDashboard from "./useServiceDashboard";
const { Text } = Typography;
const { RangePicker } = DatePicker;
const ServiceDashboard = () => {
    const {
        certificates = [],
        loading,
        totalEnrolledUsers,
        totalCertificatesReleases,
        totalCertificatesIsseuedYearly,
        certificatesIssuedMonthly,
        refresh,
        dates,
        handleDatesChange
    } = useServiceDashboard();
    const { startDate, endDate } = dates;
    return (
        <>
            <div className="leads-action-wrapper mb-2">
                <div className="leads_action_buttons">
                    <Button onClick={refresh} icon={<ReloadOutlined />} />
                </div>
                <div className="leads-action-wrapper-right">
                    <RangePicker onChange={handleDatesChange}  value={[startDate,endDate]} />
                </div>
            </div>
            <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={12} lg={6} xl={3}>
                    <Flex gap={20} vertical>
                        <Card>
                            <Statistic
                                title="Enrolled Clients"
                                value={totalEnrolledUsers}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                                prefix={<UsergroupAddOutlined />}
                            />
                        </Card>
                    </Flex>
                </Col>
                <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                    <Badge.Ribbon style={{ marginTop: 10 }} color='#438eff' text={`Total Certificate Released: ${totalCertificatesReleases || 0}`}>
                        <Card title="Certificate Released Course Wise">
                            <Spin spinning={loading}>
                                <List
                                    size="small"
                                    bordered={false}
                                    dataSource={certificates}
                                    renderItem={(item, index) =>
                                        <List.Item style={{ padding: 7, display: "flex" }}>
                                            <Text>{item.course_name}</Text>
                                            <Badge overflowCount={999999} count={item?.certification_count || 0} showZero color={badge_colors[index] || "#faad14"} />
                                        </List.Item>
                                    }
                                />
                            </Spin>
                        </Card>
                    </Badge.Ribbon>
                </Col>
                <Col xs={24} sm={24} md={20} lg={12} xl={8}>
                    <Badge.Ribbon style={{ marginTop: 10 }} color='orange' text={`Total Certificate Released: ${totalCertificatesIsseuedYearly || 0}`}>
                        <Card title="Certificate Released">
                            <Spin spinning={loading}>
                                <List
                                    size="small"
                                    bordered={false}
                                    dataSource={certificatesIssuedMonthly}
                                    renderItem={(item, index) =>
                                        <List.Item style={{ padding: 7, display: "flex" }}>
                                            <Text>{item.month}</Text>
                                            <Badge overflowCount={999999} count={item?.total_certificates || 0} showZero color={badge_colors[index] || "#faad14"} />
                                        </List.Item>
                                    }
                                />
                                <ResponsiveContainer width="100%" height={400}>
                                    <ComposedChart
                                        layout="vertical"
                                        width={500}
                                        height={500}
                                        data={certificatesIssuedMonthly}
                                        margin={{
                                            top: 30,
                                            right: 0,
                                            bottom: 20,
                                            left: -10,
                                        }}
                                    >
                                        <CartesianGrid stroke="#f5f5f5" />
                                        <XAxis type="number" />
                                        <YAxis dataKey="month" type="category" scale="band" fontSize={10} style={{ overflowX: "hidden" }} />
                                        <Tooltip />
                                        <Bar dataKey="total_certificates" barSize={20} fill="#8884d8" />
                                    </ComposedChart>
                                </ResponsiveContainer>
                            </Spin>
                        </Card>
                    </Badge.Ribbon>
                </Col>
            </Row>
        </>
    )
}
export default ServiceDashboard;

// const config = {
//     percent: 0.5,
//     height: 150,
//     width: 150,
//     style: {
//         shape: 'pin',
//         textFill: '#fff',
//         fontSize: 12,
//         outlineBorder: 4,
//         outlineDistance: 8,
//         waveLength: 128,
//     },
// };


// <Liquid {...config} />



const badge_colors = [
    "#438eff", "red", "red", "orange", "cyan", "green", "blue", "purple", "geekblue", "magenta", "volcano", "gold", "lime", "#f50", "rgb(45, 183, 245)", "#438eff"
]