import { Routes, Route } from "react-router-dom";
import LoginView from "views/login";
import OrderView from "views/orders";
import AdminOrderView from "views/admin-order-view";
import CurrencyView from "views/currency";
import { getUserRole } from "utils";
import SalesOrderView from "views/sale-orders-view";
import PmpStudentsView from "views/pmp-students";
import ServiceOrdersView from "views/service-orders-view";
import ServiceDashboard from "views/service-dashboard";
import AdminReports from "views/admin-reports";
export const AuthorizationRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<LoginView />} />
            </Routes>
        </>
    )
}

export const ApplicationRoutes = () => {
    const userRole = getUserRole();
    return (
        <>
            <Routes>
                <Route path="/" element={OrderViewEnum[userRole]} />
                <Route path="/orders" element={OrderViewEnum[userRole]} />
                <Route path="/reports" element={<ServiceDashboard />} />
                <Route path="/pmp-students" element={<PmpStudentsView />} />
                <Route path="/admin-reports" element={<AdminReports />} />
                <Route path="/currencies" element={<CurrencyView />} />
            </Routes>
        </>
    )
}

const OrderViewEnum = {
    user: <SalesOrderView />,
    admin: <AdminOrderView />,
    accounts: <OrderView />,
    service: <ServiceOrdersView />,
    manager: <SalesOrderView />,
}