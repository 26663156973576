import { Button, Modal, Input, Space, Timeline, Divider, Spin } from 'antd';
import useAddOrderRemarks from "./useAddOrderRemarks";
import moment from 'moment';
const { TextArea } = Input;
const AddOrderRemarks = ({ order_id, isOpen = false, onClose = () => { }, remarks = [], refetch = () => { } }) => {
    console.log("remarks",remarks);
    const {
        loading,
        handleUpdateRemarks,
        setNewRemark
    } = useAddOrderRemarks(order_id);
    return (
        <Modal
            title="Add Remarks"
            centered
            open={isOpen}
            footer={null}
            onCancel={() => onClose(false)}
            width={300}
        >
            <Spin spinning={loading}>
                <Space size={10} direction="vertical" style={{ width: "100%" }}>
                    <TextArea onChange={(e) => setNewRemark(e.target.value)} placeholder='Add Remarks here' rows={4} />
                    <Button onClick={() => handleUpdateRemarks(remarks, refetch, onClose)} block type='primary'>Save Remark</Button>
                    {
                        remarks !== "" && typeof remarks === "string"
                            ?
                            <>
                                <Divider />
                                <Timeline
                                    items={JSON.parse(remarks).filter((x) => x.type !== "service_onboarding").map((item) => ({ children: `${item.message} - ${moment(item.date).format("MMMM Do YYYY")}` }))}
                                />
                            </>
                            :
                            null
                    }
                </Space>
            </Spin>
        </Modal>
    )
}

export default AddOrderRemarks;