import { Table, Typography } from "antd";
const { Text } = Typography;
const CostByCountry = ({ data = [] }) => {
    return (
        <Table
            size="small"
            pagination={false}
            dataSource={data}
            columns={columns} />
    )
}

export default CostByCountry;

const columns = [
    {
        title: 'Region',
        dataIndex: 'region',
        key: 'name',
        render: (_, record) => {
            return <Text>{region_enums[record?.region].title}</Text>
        }
    },
    {
        title: 'Total Leads',
        dataIndex: 'total',
        key: 'total',
        render: (_, record) => {
            return <Text>{record?.total_leads}</Text>
        }
    },
    {
        title: 'Total Cost',
        dataIndex: 'cost',
        key: 'cost',
        render: (_, record) => {
            return <Text>{(record?.total_cost?.toFixed(0) + " " + region_enums[record?.region].currency) || "NA"}</Text>
        }
    },
    {
        title: 'Won Leads',
        dataIndex: 'won',
        key: 'total',
        render: (_, record) => {
            return <Text>{record?.total_won_leads}</Text>
        }
    },
    {
        title: 'Conversion',
        dataIndex: 'conversion',
        key: 'conversion',
        render: (_, record) => {
            return <Text>{((record?.total_won_leads / record?.total_leads) * 100)?.toFixed(1)}%</Text>
        }
    },
];


const region_enums = {
    1: { title: "Abu Dhabi", currency: "AED" },
    2: { title: "Noida", currency: "INR" },
    3: { title: "Karachi", currency: "PKR" },
    4: { title: "Banglore", currency: "INR" },
    5: { title: "Gurugram", currency: "INR" },
}