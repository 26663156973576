import { useState } from "react";
import { getActivity } from "services";
import moment from "moment";
import { useQuery } from "react-query";

const useOrderTimeline = (orderId) => {
  const [activityData, setActivityData] = useState([]);
  const { isLoading } = useQuery({
    queryKey: ["/order-timeline", orderId],
    queryFn: () => handleGetActivity(),
    keepPreviousData: false,
  });
  const handleGetActivity = () => {
    getActivity(orderId).then((result) => {
      if (result.status === 1) {
        const items = result.data.map((item) => {
          const label = moment(item.created).format("MMMM Do YYYY");
          const children = `${item.activity} - ${
            item.user.f_name + " " + item.user.l_name
          }`;
          return { label, children };
        });
        setActivityData(items);
      }
    });
  };
  return { activityData, isLoading };
};
export default useOrderTimeline;
