import { Button, Modal, Typography, Row, Col, Badge, DatePicker, Select } from "antd";
import { FilterOutlined, CloseOutlined } from "@ant-design/icons";
import useOrderFilter from "./useOrderFilter";
import UserTile from "components/user-tile";
import { payment_methods } from "enums";
const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const OrderFilter = () => {
    const {
        isOpen,
        setIsOpen,
        filterApplied,
        handleRemoveFilter,
        users,
        handleChange,
        handleOnFilterLeads
    } = useOrderFilter();
    return (
        <>
            <Badge count={filterApplied}>
                <Button onClick={() => setIsOpen(!isOpen)} icon={<FilterOutlined />}>
                    Add Filter
                </Button>
            </Badge>
            {
                filterApplied > 0
                    ?
                    <Button icon={<CloseOutlined />} danger type="dashed" onClick={handleRemoveFilter}>Remove Filter</Button>
                    :
                    null
            }
            <Modal
                title="Filter Orders"
                centered
                open={isOpen}
                onOk={() => { }}
                okText="Filter Orders"
                footer={null}
                width={400}
                onCancel={() => setIsOpen(!isOpen)}
            >
                <Row style={{ marginBottom: 20 }} gutter={16}>
                    <Col span={24}>
                        <Text strong>Relationship Manager:</Text>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                                marginTop: 10,
                            }}
                            placeholder="Please select"
                            onChange={(value) => handleChange("relationshipManagers", value)}
                            optionLabelProp="label"
                        >
                            {users &&
                                users
                                    .filter((x) => ["user", "manager"].includes(x.user_type))
                                    .sort(function (a, b) {
                                        if (a.f_name < b.f_name) {
                                            return -1;
                                        }
                                        if (a.f_name > b.f_name) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map((item, index) => (
                                        <Option
                                            key={`User Relationship Manager Dropdown ${item.f_name + index
                                                }`}
                                            // value={item.id}
                                            value={item.f_name}
                                            label={item.f_name + " " + item.l_name}
                                        >
                                            <UserTile
                                                avatarSize={30}
                                                alignment="row"
                                                assignedUser={item}
                                            />
                                        </Option>
                                    ))}
                        </Select>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} gutter={16}>
                    <Col span={24}>
                        <Text strong>Region:</Text>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                                marginTop: 10,
                            }}
                            placeholder="Please select"
                            onChange={(value) => handleChange("region", value)}
                            options={[
                                { label: "Abu Dhabi", value: 1 },
                                { label: "Pakistan", value: 3 },
                                { label: "Noida", value: 2 },
                                { label: "Banglore", value: 4 },
                            ]}
                        />
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} gutter={16}>
                    <Col span={24}>
                        <Text strong>Platform:</Text>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                                marginTop: 10,
                            }}
                            placeholder="Please select"
                            onChange={(value) => handleChange("platform", value)}
                        >
                           {payment_methods
                                                        .sort(function (a, b) {
                                                            if (a.title < b.title) {
                                                                return -1;
                                                            }
                                                            if (a.title > b.title) {
                                                                return 1;
                                                            }
                                                            return 0;
                                                        })
                                                        .map((gateway) => (
                                                            <Option
                                                                key={`Payment Gateways ${gateway.title}`}
                                                                value={gateway.title}
                                                            >
                                                                {gateway.title}
                                                            </Option>
                                                        ))}
                        </Select>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} gutter={16}>
                    <Col span={24}>
                        <Text strong>Marketer:</Text>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: "100%",
                                marginTop: 10,
                            }}
                            placeholder="Please select"
                            onChange={(value) => handleChange("marketers", value)}
                        >
                            {users &&
                                users
                                    .filter((x) => x.user_type === "marketer")
                                    .sort(function (a, b) {
                                        if (a.f_name < b.f_name) {
                                            return -1;
                                        }
                                        if (a.f_name > b.f_name) {
                                            return 1;
                                        }
                                        return 0;
                                    })
                                    .map((item, index) => (
                                        <Option
                                            key={`User Marktere Dropdown ${item.f_name + index}`}
                                            value={item.f_name.toLowerCase()}
                                            label={item.f_name + " " + item.l_name}
                                        >
                                            <UserTile
                                                avatarSize={30}
                                                alignment="row"
                                                assignedUser={item}
                                            />
                                        </Option>
                                    ))}
                        </Select>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 20 }} gutter={16}>
                    <Col span={24}>
                        <Text strong>Date To:</Text>
                        <RangePicker style={{ width: "100%", marginTop: 10, }} onChange={(value) => handleChange("range", value)} />
                    </Col>
                </Row>
                <Button
                    // disabled={disabled}
                    onClick={handleOnFilterLeads}
                    style={{ marginTop: 10 }}
                    type="primary" block={true}>
                    Filter Orders
                </Button>
            </Modal>
        </>
    )
}
export default OrderFilter;