import { Tag, Badge } from "antd";
import { UaeFlagIcon, IndiaFlagIcon, PakistanFlagIcon } from "assets/icons";
import moment from "moment";
const build_types = ["build", "local"];
export const application_status = build_types[0];
export const apiBaseURL =
    application_status === "build"
        ? "https://api.imclearning.com/api/"
        : "http://localhost:4000/api/";

export const getOrderAccountStatus = (status) => {
    return (
        <>
            <Tag color={status ? "success" : "processing"}>
                {!status ? "pending" : "Approved"}
            </Tag>
        </>
    );
};

export const user_roles = {
    service: {
        showServiceOption: true,
        showAccountsOption: false,
        allowedOrderColumns: [
            // "Id",
            "Status",
            "Name/Email",
            "Relationship Manager",
            "Marketer",
            "Action",
        ],
    },
    account: {
        showServiceOption: false,
        showAccountsOption: true,
        allowedOrderColumns: [
            // "Id",
            "Status",
            "Name/Email",
            "Designation/Company",
            "Revenue",
            "Paid Amount",
            "Balance Amount",
            "Relationship Manager",
            "Marketer",
            "Summary",
            "Action",
        ],
    },
    admin: {
        showServiceOption: true,
        showAccountsOption: true,
        allowedOrderColumns: [
            // "Id",
            "Status",
            "Designation/Company",
            "Name/Email",
            "Revenue",
            "Summary",
            "Balance Amount",
            "Paid Amount",
            "Relationship Manager",
            "Marketer",
            "Action",
        ],
    },
};

export const marketers = {
    priya: {
        title: "Priya",
        designation: "Marketer",
        image: "https://www.imcinstitute.ae/website-resources/team-photos/priya.jpg"
    },
    farhan: {
        title: "Farhan",
        image:
            "https://www.imcinstitute.ae/website-resources/team-photos/team017.jpg",
        designation: "Director",
    },
    khalid: {
        title: "Khalid",
        image:
            "https://www.imcinstitute.ae/website-resources/team-photos/khalid.png",
        designation: "Marketer",
    },
    Khalid: {
        title: "Khalid",
        image:
            "https://www.imcinstitute.ae/website-resources/team-photos/khalid.png",
        designation: "Marketer",
    },
    wasif: {
        title: "Wasif",
        image:
            "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-2048x1949-pq9uiebg.png",
        designation: "Marketer",
    },
    Kunal: {
        title: "Kunal",
        image:
            "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-2048x1949-pq9uiebg.png",
        designation: "Marketer",
    },
    kiya: {
        title: "Kiya",
        image:
            "https://www.imcinstitute.ae/website-resources/team-photos/team016.jpg",
        designation: "Director",
    },
    null: {
        title: "Reference",
        image:
            "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-2048x1949-pq9uiebg.png",
        designation: "",
    },
};

export const getOrderPaymentStatus = (sale_amount, payments) => {
    // Calculate the total amount paid from the payments array
    const totalPaidAmount = payments
        .filter((x) => x.payment_status === 0)
        .reduce((total, payment) => total + payment.payment_amount, 0);

    // Calculate the percentage of the sale amount that has been paid
    const percentPaid = (totalPaidAmount / sale_amount) * 100;
    return {
        percentPaid: isNaN(percentPaid) ? 0 : percentPaid,
        totalPaidAmount: isNaN(percentPaid) ? 0 : totalPaidAmount,
    };
};

export const getPaymentStatus = (payment_status) => {
    return (
        <>
            <Tag color={payment_status_enums[payment_status].color}>
                {payment_status_enums[payment_status].title}
            </Tag>
        </>
    );
};

export const getOrderUserStatus = (login_generated) => {
    return (
        <>
            {[true, false, null, 0, 1, 2, 3].includes(login_generated) ? (
                <Tag color={order_user_status_enums[login_generated].color}>
                    {order_user_status_enums[login_generated].title}
                </Tag>
            ) : null}
        </>
    );
};

const payment_status_enums = {
    0: { title: "Completed", color: "success" },
    1: { title: "Refunded", color: "error" },
};

export const available_currencies = [
    {
        label: "USD",
        value: "USD",
        symbol: "$",
    },
    {
        label: "PKR",
        value: "PKR",
        symbol: "Rs",
    },
    {
        label: "India",
        value: "INR",
        symbol: "₹",
    },
    {
        label: "Saudia Arabia",
        value: "SAR",
        symbol: "SAR",
    },
    {
        label: "Omani Riyal",
        value: "OMAN",
        symbol: "OMR",
    },
    {
        label: "Kuwait Dinar",
        value: "KWD",
        symbol: "KWD",
    },
    {
        label: "Egypt",
        value: "EGP",
        symbol: "EGP",
    },
    {
        label: "Bahrain",
        value: "BHD",
        symbol: "BHD",
    },
    {
        label: "Phillipene",
        value: "PHP",
        symbol: "PHP",
    },
];

export const getRegionInformation = (regionId) => {
    const parseRegionId = parseInt(regionId);
    if (isNaN(parseRegionId)) return false;
    return getRegion[parseRegionId];
};
export const getRegion = {
    3: { title: "Pakistan", color: "green" },
    2: { title: "India", color: "orange" },
    1: { title: "Abu Dhabi", color: "geekblue" },
    0: { title: "No Region", color: "red" },
};

export const default_password_lms_users =
    "Imc@123";

export const order_user_status_enums = {
    false: { title: "Registered", color: "blue" },
    null: { title: "Registered", color: "blue" },
    1: { title: "Enrolled", color: "green" },
    true: { title: "Enrolled", color: "green" },
    2: { title: "Blocked", color: "red" },
};

export const payment_methods = [
    {
        title: "Payfort",
        charges: "5%",
    },
    {
        title: "Cashew",
        charges: "5%",
    },
    {
        title: "Tabby",
        charges: "5%",
    },
    {
        title: "Paypal",
        charges: "5%",
    },
    {
        title: "Fatoorah",
        charges: "5%",
    },
    {
        title: "Razorpay",
        charges: "18%",
    },
    {
        title: "Stripe",
        charges: "5%",
    },
    {
        title: "Tamara - Saudi",
        charges: "5%",
    },
    {
        title: "Tamara - Master Account",
        charges: "5%",
    },
    {
        title: "ADCB",
        charges: "5%",
    },
    {
        title: "Yes Bank",
        charges: "18%",
    },
    {
        title: "Pakistan account",
        charges: "5%",
    },
    {
        title: "Cash",
        charges: "0%",
    },
];


export const allowedRoutes = {
    user: ["/"],
    accounts: ["/", "/orders", "/currencies"],
    admin: ["/", "/reports","/admin-reports"],
    service: ["/", "/reports"],
    manager: ["/"],
}

export const getOrderStatus = (record) => {
    let payload = {
        text: "NA",
        status: "default"
    };
    if (!record.form) {
        payload.text = "Form Pending";
        payload.status = "warning";
    } else if (!record.accounts_verification) {
        payload.text = "Accounts Pending";
        payload.status = "processing";
    } else {
        payload.text = "Order Complete";
        payload.status = "success";
    }
    return (
        <Badge status={payload?.status} text={payload?.text} />
    )
}

export const getServiceStatus = (service_call, service_date, region) => {
    let payload = {
        text: "Service Call Pending!",
        status: "processing"
    };
    if (service_call) {
        payload.text = `Connected on ${moment(service_date).format("MMM Do YYYY")}`;
        payload.status = "success";
    }
    if (region !== 1) {
        payload.text = "Not Applicable";
        payload.status = "default";
    }
    return (
        <Badge status={payload?.status} text={payload?.text} />
    )
}


export const getPaymentPendingStatus = (sale_amount, payments) => {
    const totalPaidAmount = payments.filter(x => x.payment_status === 0).reduce((accumulator, item) => { return accumulator += item.payment_amount }, 0);
    let payload = {
        text: "Fully Paid!",
        color: "success"
    };
    if (sale_amount > totalPaidAmount || sale_amount == null) {
        payload.text = "Payment Pending";
        payload.color = "error";
    }
    return (
        <Tag color={payload?.color}>{payload?.text}</Tag>
    )

}

const regions = {
    1: {
        title: "UAE",
        icon: <UaeFlagIcon />
    },
    2: {
        title: "India",
        icon: <IndiaFlagIcon />
    },
    3: {
        title: "Pakistan",
        icon: <PakistanFlagIcon />
    },
    4: {
        title: "India",
        icon: <IndiaFlagIcon />
    },
}

export const getCustomerRegion = (region) => {
    let payload = {
        icon: regions[region]?.icon,
        title: regions[region]?.title || "NA"
    };
    return (
        <Tag bordered={false} icon={payload.icon} style={{ display: "flex", backgroundColor: "transparent", padding: 0, gap: 10, alignItems: "center", width: "max-content" }}>
            {payload.title}
        </Tag>
    )
}
export const pmp_application_status_enum = {
    0: { label: "No Status", color: "red", value: 0, },
    1: { label: "Account Created", color: "pink", value: 1, },
    2: { label: "Account Not Created", color: "yellow", value: 2, },
    3: { label: "Application Approval", color: "orange", value: 3, },
    4: { label: "Approved", color: "green", value: 4, },
    5: { label: "Disapproved", color: "cyan", value: 5, },
    6: { label: "YT Scheduled Exam", color: "blue", value: 6, },
    7: { label: "Exam Scheduled", color: "purple", value: 7, },
    8: { label: "Exam Passed", color: "geekblue", value: 8, },
    9: { label: "Waiting for result", color: "magenta", value: 9, },
    10: { label: "Application filling-on process", color: "volcano", value: 10, },
    11: { label: "Exam Failed", color: "gold", value: 11, },
}
export const getPmpApplicationStatus = (status) => {
    return <Badge color={pmp_application_status_enum[status]?.color} text={pmp_application_status_enum[status]?.label} />
}

