import {
    Button,
    Tooltip,
    Input,
    Typography,
    Table,
    // Avatar,
    // Tag,
    Space,
} from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import useSaleOrderView from "./useSaleOrderView";
// import { TotalBalanceIcon, TotalOrdersIcon, RewardsPointIcon, OrderSuccessfullIcon } from "assets/icons";
// import InfographicCard from "components/infographic-card";
import { getOrderUserStatus, marketers, getOrderStatus } from "enums";
import moment from "moment";
import OrderFormModal from "components/modals/order-form";
import ViewOrderModal from "components/modals/view-order";
import SaleFilter from "components/sale-filter";
import OrderService from "components/order-service";
const targetDate = new Date('2024-07-10');
const { Search } = Input;
const { Text } = Typography;
const SalesOrderView = () => {
    const {
        onSearch,
        refreshData,
        records,
        total,
        tableLoading,
        currencies,
        currentPageNo,
        changePage,
        searchValue,
        refetch
    } = useSaleOrderView();
    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            ellipsis: true,
            key: "id",
        },
        {
            title: "Order Date",
            dataIndex: "order_date",
            key: "order_date",
            render: (_, record) => {
                const date = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <>
                        {/* {getOrderUserStatus(record.login_generated)}<br /> */}
                        <Tooltip placement="bottom" title={date}>
                            <Text>{date}</Text>
                        </Tooltip>
                    </>
                )
            },
        },
        {
            title: "Name",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {
                return (
                    <>
                        <Text>
                            {record?.lead?.name}<br />
                            {record?.lead?.country}
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Phone/Email",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {

                return (

                    <>
                        <Text>
                            {record?.lead?.phone}
                            <br />
                            {record?.lead?.email}
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Designation/Company",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => (
                <>
                    <Text>
                        {record?.lead?.designation}
                        <br />
                        {record?.lead?.company}
                    </Text>
                </>
            ),
        },
        // {
        //     title: "Marketer",
        //     dataIndex: "marketer",
        //     key: "marketer",
        //     render: (_, record) => {
        //         const { marketer } = record?.lead;
        //         const selectedMarketer = marketers[marketer];
        //         return (
        //             <>
        //                 <div className="profile-box">
        //                     <Avatar size={40} src={selectedMarketer?.image} />
        //                     <div className="profile-detail-information">
        //                         <Text>{selectedMarketer?.title || "NA"}</Text>
        //                         <Tag style={{ maxWidth: "fit-content" }} color="geekblue">
        //                             {selectedMarketer?.designation || "NA"}
        //                         </Tag>
        //                     </div>
        //                 </div>
        //             </>
        //         );
        //     },
        // },
        {
            title: "User Status",
            dataIndex: "order_status",
            key: "order_status",
            render: (_, record) => {
                return (
                    <>
                        {getOrderUserStatus(record.login_generated)}
                    </>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "order_status",
            key: "order_status",
            render: (_, record) => {
                return (
                    <>
                        {getOrderStatus(record)}
                    </>
                );
            },
        },
        {
            title: "Action",
            key: "action",
            // fixed: 'right',
            // ellipsis: true,
            render: (_, record) => {
                const orderDate = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                const recordDate = new Date(record.order_date);
                return (
                    <Space>
                        {
                            record.form
                                ?
                                <ViewOrderModal form={JSON.parse(record.form.form_response)} orderDate={orderDate} />
                                :
                                null
                        }
                        {
                            record.form && record.accounts_verification
                                ?
                                null
                                :
                                recordDate >= targetDate
                                    ?
                                    <OrderFormModal
                                        callBack={() => refetch()}
                                        currencies={currencies}
                                        form={record.form}
                                        mode={record.form ? "edit" : "add"}
                                        orderId={record.id}
                                        orderDate={orderDate}
                                        lead={record.lead} />
                                    :
                                    null
                        }
                        {
                            record.lms_user_id
                                ?
                                <OrderService
                                    userType="user"
                                    orderId={record.id}
                                    userId={record.lms_user_id}
                                    callBack={() => refetch()}
                                />
                                : null
                        }
                    </Space>
                )
            },
        },
    ];
    return (
        <>
            <div className="leads-action-wrapper">
                <div className="leads_action_buttons">
                    <Tooltip placement="bottom" title="Reload">
                        <Button onClick={refreshData} icon={<ReloadOutlined />} >Refresh</Button>
                    </Tooltip>
                </div>
                <div className="leads-action-wrapper-right">
                    <Search
                        placeholder="input search text"
                        onSearch={onSearch}
                        allowClear
                        onPressEnter={onSearch}
                        loading={false}
                        defaultValue={searchValue}
                        style={{ maxWidth: 400 }}
                    />
                    <SaleFilter />

                </div>
            </div>
            {/* <div className="d-flex gap-2 flex-wrap" style={{marginTop:10}}>
                <InfographicCard title="Total Appointments" value="40" icon={<TotalBalanceIcon />} />
                <InfographicCard title="Total Payment" value="$75200" icon={<TotalOrdersIcon />} />
                <InfographicCard title="Order Successful" value="1290 " icon={<OrderSuccessfullIcon />} />
                <InfographicCard title="Rewards Point" value="1400" icon={<RewardsPointIcon />} />
            </div> */}
            <div className="lead-table">
                <Table
                    columns={columns}
                    rowClassName={(_, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    dataSource={records}
                    bordered={true}
                    loading={tableLoading}
                    size="medium"
                    tableLayout="fixed"
                    showHeader={true}
                    pagination={{
                        position: ["none", "bottomCenter"],
                        total: total,
                        hideOnSinglePage: true,
                        current: parseInt(currentPageNo),
                        showLessItems: true,
                        onChange: (page, pageSize) => {
                            window.scrollTo(0, 0);
                            changePage(page, pageSize);
                        },
                    }}
                    scroll={{ x: 1500 }}
                    sticky={true}
                />
            </div>
        </>
    )
}
export default SalesOrderView;


