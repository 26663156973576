import { Button, Modal, Input, Space, } from 'antd';
import useChangeEmail from "./useChangeEmail";
const ChangeEmail = ({ isOpen = false, onClose = () => { }, userId, orderId }) => {
    const { updateLMSEmail, loading, inputError, setEmail } = useChangeEmail();
    return (
        <>
            <Modal
                title="Change Email"
                centered
                open={isOpen}
                footer={null}
                onCancel={() => onClose(false)}
                width={300}
            >
                <Space size={10} direction="vertical" style={{ width: "100%" }}>
                    <Input status={inputError ? "error" : ""} onChange={(e) => setEmail(e.target.value)} allowClear placeholder="Enter New Email" />
                    <Button onClick={() => updateLMSEmail(userId, orderId, () => onClose(false))} loading={loading} block type='primary'>Save Email</Button>
                </Space>
            </Modal>
        </>
    )
}
export default ChangeEmail;