import { useState, useEffect } from "react";
import { getAllOrders, getAllCurrency } from "services";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getUserRole } from "utils";

const useAdminOrderView = () => {
    const userRole = getUserRole();
    const [searchParams, setSearchParams] = useSearchParams();
    const [currencies, setCurrencies] = useState([]);
    const [showOrderActivity, setShowOrderActivty] = useState(false);
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        limit: searchParams.get("limit") || 50,
        search: searchParams.get("search") || "",
        filters: searchParams.get("search") || "{}",
        total: 0,
    });
    useEffect(() => {
        getAllCurrency().then((result) => {
            if (result.status === 1) {
                setCurrencies(result.data);
            }
        })
    }, [])
    useEffect(() => {
        const hasFilter = searchParams.get("filters");
        if (hasFilter) {
            setParams({ ...params, filters: hasFilter, page: 1 });
        }
    }, [searchParams])
    const { isLoading, data, refetch } = useQuery({
        queryKey: ["/get-orders", params],
        queryFn: () => getAllOrders(params),
        keepPreviousData: false,
    });
    const handleSearchParams = (key, value) => {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    };
    const changePage = (page, limit) => {
        handleSearchParams("page", page);
        handleSearchParams("limit", limit);
        setParams({ ...params, page: page, limit: limit });
    };
    const onSearch = (e) => {
        const searchValue = e === "" ? "" : e.target.value;
        handleSearchParams("search", searchValue);
        handleSearchParams("page", 1);
        setParams({ ...params, search: searchValue, page: 1 });
        // refreshData();
    };



    return {
        refetch: () => refetch(),
        records: data?.data?.data || [],
        total: data?.data?.total,
        currentPageNo: params.page || 1,
        tableLoading: isLoading,
        currencies,
        showOrderActivity,
        setShowOrderActivty,
        changePage,
        onSearch,
        userRole,
        pageSize: params.limit || 15,
    }
}
export default useAdminOrderView;

