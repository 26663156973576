import { Button, Modal, Descriptions, Typography, Collapse, List, Popconfirm, Progress, Tooltip, Alert, Tag } from 'antd';
import EmailPreview from "components/email-preview";
import { SmileOutlined, EyeOutlined } from "@ant-design/icons";
import useViewOrder from "./useViewOrder";
const { Text } = Typography;
const ViewOrderModal = ({
    form,
    upgradedOrder = false,
    size = "medium",
    orderDate,
    isAdmin,
    isApproved,
    formId,
    orderId,
    counsellor,
    enrolledAt,
    callBack = () => { },
    showPayment = true
}) => {
    const { modal, setModal, emailPreview, setEmailPreview, progress, handleApproveOrder } = useViewOrder();
    const { client_details: { name, email, company, designation, country, phone, whatsApp, industry },
        course_details,
        payment_details: { paid_amount, payment_currency, payment_method, payment_type, total_revenue, total_revenue_aed },
        service_details: { complimentory_courses, remarks }
    } = form;
    const items = [
        {
            key: '1',
            label: 'Type',
            span: 3,
            children: <Tag color={upgradedOrder ? 'green' : 'geekblue'}>{upgradedOrder ? "Upgraded Client" : "New Customer"}</Tag>,
        },
        {
            key: '2',
            label: 'Date',
            span: 3,
            children: orderDate,
        },
        {
            key: '3',
            label: 'Name',
            span: 3,
            children: <Text strong type="danger">{name}</Text>,
        },
        {
            key: '4',
            label: 'Email',
            span: 4,
            children: <Text strong type="danger">{email}</Text>
        },
        {
            key: '5',
            label: 'Phone',
            span: 1,
            children: phone,
        },
        {
            key: '6',
            label: 'WhatsApp',
            span: 2,
            children: whatsApp ? whatsApp : phone,
        },
        {
            key: '7',
            label: 'Company',
            children: company,
            children: <Text>{company}</Text>

        },
        {
            key: '8',
            span: 2,
            label: 'Designation',
            children: designation,
        },
        {
            key: '9',
            label: 'Country',
            children: country,
        },
        {
            key: "10",
            label: "Industry",
            children: industry || "NA"
        }

    ];
    const percentage = parseInt((paid_amount / total_revenue) * 100);
    return (
        <>
            <Tooltip placement="bottom" title="View Order"><Button size={size} type="dashed" icon={<EyeOutlined />} onClick={() => setModal(true)} /></Tooltip>
            <EmailPreview
                paymentDetails={{ paid_amount, payment_currency, payment_method, payment_type, total_revenue }}
                isOpen={emailPreview}
                onClose={setEmailPreview} />
            {
                modal
                    ?
                    <Modal
                        title="Payment Form"
                        centered
                        open={modal}
                        footer={null}
                        onCancel={() => setModal(false)}
                        width={700}
                    >
                        <Descriptions size='small' bordered items={items} />
                        {
                            showPayment
                                ?
                                <Collapse
                                    size="small"
                                    style={{ marginTop: 10 }}
                                    items={[
                                        {
                                            key: '1',
                                            label: 'Payment Information',
                                            extra: <Button onClick={(event) => {
                                                event.stopPropagation();
                                                setEmailPreview(true)
                                            }} type="ghost" size='small'>Preview</Button>,
                                            children:
                                                <>
                                                    <Descriptions size='small' items={[
                                                        {
                                                            span: 3,
                                                            key: '0',
                                                            label: 'Total Revenue (AED)',
                                                            children: `${total_revenue_aed} AED`,
                                                        },
                                                        {
                                                            span: 2,
                                                            key: '1',
                                                            label: 'Total Revenue',
                                                            children: `${total_revenue} ${payment_currency}`,
                                                        },
                                                        {
                                                            span: 2,
                                                            key: '2',
                                                            label: 'Paid Amount',
                                                            children: `${paid_amount} ${payment_currency}`,
                                                        },
                                                        {
                                                            key: '3',
                                                            label: 'Payment Method',
                                                            children: `${payment_method}`,
                                                            span: 2
                                                        },
                                                        {
                                                            span: 2,
                                                            key: '4',
                                                            label: 'Payment Type',
                                                            children: `${payment_type}`,
                                                        },
                                                    ]} />
                                                    <Alert
                                                        message={percentage >= 33 && percentage <= 100 ? `Payment is ${percentage}% so credientails will be dispatched automatically` : `Payment is ${percentage}% which is less then 33% so credientials will not dispatched`}
                                                        type={percentage >= 33 && percentage <= 100 ? "success" : "error"}
                                                        showIcon />
                                                </>
                                            ,
                                        }
                                    ]}
                                />
                                :
                                null
                        }
                        <Collapse
                            size="small"
                            style={{ marginTop: 10 }}
                            items={[
                                {
                                    key: '1',
                                    label: 'Course Information',
                                    children: <List
                                        bordered
                                        size='small'
                                        dataSource={course_details}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={item.courseName}
                                                    description={item.description}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                }
                            ]}
                        />
                        <Collapse
                            size="small"
                            style={{ marginTop: 10, marginBottom: 10 }}
                            items={[
                                {
                                    key: '1',
                                    label: 'Complimentory &  Service',
                                    children: <List
                                        size='small'
                                        dataSource={complimentory_courses}
                                        renderItem={(item) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    title={item.courseName}
                                                />
                                            </List.Item>
                                        )}
                                    />
                                }
                            ]}
                        />
                        {
                            remarks
                                ?
                                <div className='d-flex flex-col'>
                                    <Text strong>Counsellor Remarks :</Text>
                                    <Text>{remarks}</Text>
                                </div>
                                :
                                null
                        }
                        {isAdmin && !isApproved ?
                            <>
                                <Progress percent={progress} />
                                <Popconfirm
                                    placement="top"
                                    title="Are you sure you want to approve this order"
                                    description="This action is not reversable!"
                                    okText="Approve"
                                    cancelText="Cancel"
                                    onConfirm={() => handleApproveOrder({ formId, orderId, counsellor, enrolledAt }, callBack)}
                                >
                                    <Button
                                        disabled={percentage > 104}
                                        icon={<SmileOutlined />}
                                        type='primary'
                                        block>
                                        Approve Order
                                    </Button>
                                </Popconfirm>
                            </>
                            : null
                        }
                    </Modal>
                    :
                    null
            }
        </>
    )
}

ViewOrderModal.defaultProps = {
    form: {},
    orderDate: "",
    isAdmin: false,
    isApproved: false,
    formId: -1,
    orderId: -1,
    counsellor: "webmaster@imcinstitute.ae",
    enrolledAt: 35
};
export default ViewOrderModal;


