export const getUserData = () => {
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    return userData;
}

export const getUserRole = () => {
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    return userData.user_type;
}

export const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}


