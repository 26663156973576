import { useState } from "react";
import { saveForm, updateForm } from "services";
const useOrderForm = (orderId, form) => {
    const [modal, setModal] = useState(false);
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({
        client_details: {},
        payment_details: {},
        course_details: {},
        service_details: {}
    })
    const handleSteps = (title, values, callBack) => {
        const payload = { ...formData, [title]: values };
        setFormData(payload);
        setStep(step + 1);
        if (step === 3) {
            const form_id = form?.id;
            let api = form_id ? updateForm : saveForm;
            const apiPayload = {
                order_id: orderId,
                form_response: payload,
                form_id: form_id
            };
            api(apiPayload).then(async (result) => {
                if (result.status === 1) {
                    callBack();
                    setStep(0);
                    setModal(false);
                }
            })
        }
    }
    return {
        modal,
        setModal,
        step,
        handleSteps
    }
}
export default useOrderForm;