import { App as AntApp, ConfigProvider, Spin } from "antd";
import ErrorBoundary from "components/error-boundry";
import "assets/css/stylesheet.scss";
import { AuthorizationRoutes, ApplicationRoutes } from "routes";
import useApp from "./useApp";
import Header from "components/header";
import Sidebar from "components/sidebar";
import { QueryClient, QueryClientProvider } from "react-query";
import { LoadingOutlined } from '@ant-design/icons';
function App() {
  const { loading, isLoggedIn, isMobile } = useApp();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: {
            /* here is your global tokens */
          },
        }}
      >
        <AntApp>
          <ErrorBoundary>
            {
              isLoggedIn && !loading
                ?
                <>
                  {isMobile ? <Header /> : null}
                  <main className="main">
                    {!isMobile ? <Sidebar /> : null}
                    <section className="main-section">
                      <ApplicationRoutes />
                    </section>
                  </main>
                </>
                :
                loading
                  ?
                  <div className="application-loading">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 70,
                          }}
                          spin
                        />
                      }
                    />
                  </div> :
                  <AuthorizationRoutes />
            }
          </ErrorBoundary>
        </AntApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

export default App;
