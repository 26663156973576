import useOrderView from "./useOrderView";
import {
    Button,
    Tooltip,
    Input,
    Tag,
    Typography,
    Avatar,
    Space,
    Table
} from "antd";
import { ReloadOutlined, ProfileOutlined, CopyOutlined } from "@ant-design/icons";
import PaymentDrawer from "components/payment-drawer";
// import { TotalBalanceIcon, TotalOrdersIcon, RewardsPointIcon, OrderSuccessfullIcon } from "assets/icons";
// import InfographicCard from "components/infographic-card";
import { marketers } from "enums";
import moment from "moment";
import OrderFormModal from "components/modals/order-form";
import ViewOrderModal from "components/modals/view-order";
import UserTile from "components/user-tile";
import OrderDate from "components/order-date";
import PaymentSummary from "components/payment-summary";
import OrderTimeline from "components/order-timeline";
import OrderPaymentStatus from "components/order-payment-status";
import OrderFilter from "components/order-filter";
const { Search } = Input;
const { Text } = Typography;
const OrderView = () => {
    const {
        refresh,
        changePage,
        records,
        onSearch,
        total,
        tableLoading,
        currencies,
        showOrderActivity,
        currentPageNo,
        setShowOrderActivty,
        userRole,
        refetch,
        filterColumns,
        pageSize,
        copyToClipboard
    } = useOrderView();
    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            // width: 100,
            key: "id",
            render: (_, record) => {
                const date = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <>
                        <div className="d-flex flex-col">
                            <Text>{record.id}</Text>
                            <Tooltip placement="bottom" title={date}>
                                <Text>{date}</Text>
                            </Tooltip>
                        </div>
                    </>
                )
            },
        },
        {
            title: "Date",
            dataIndex: "id",
            key: "id"
        },
        {
            title: "Status",
            dataIndex: "order_date",
            key: "order_date",
            ellipsis: true,
            render: (_, record) => {
                return (
                    <>
                        {/* {getOrderUserStatus(record.login_generated)} */}
                        <OrderDate
                            key={`Order Date ${record.id}`}
                            // onDateChange={(value) =>
                            // handleUpdateOrder(value, record.id, "order_date")
                            // }
                            record={record}
                        />
                    </>
                )
            },
        },
        {
            title: "Name/Email",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => {

                return (

                    <>
                        <Text>
                            {record?.lead?.name}
                            <br />
                            {record?.lead?.email}
                        </Text>
                    </>
                )
            },
        },
        {
            title: "Designation/Company",
            dataIndex: "name",
            ellipsis: true,
            key: "name",
            render: (_, record) => (
                <>
                    <Text>
                        {record?.lead?.designation}
                        <br />
                        {record?.lead?.company}
                    </Text>
                </>
            ),
        },

        {
            title: "Summary",
            dataIndex: "summary",
            key: "summary",
            width: 360,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <PaymentSummary size="small" payments={record?.payments || []} />
                </>
            ),
        },
        {
            title: "Balance Amount",
            dataIndex: "payment_status",
            key: "payment_status",
            width: 160,
            ellipsis: true,
            render: (_, record) => (
                <>
                    <OrderPaymentStatus record={record} />
                </>
            ),
        },
        {
            title: "Relationship Manager",
            dataIndex: "sale_amount",
            key: "sale_amount",
            width: 180,
            render: (_, record) => (
                <>
                    {record?.relationship_manager ? (
                        <UserTile userId={record?.sold_by || null} assignedUser={record?.relationship_manager || {}} />
                    ) : (
                        <Text>Not Assgined to any user</Text>
                    )}
                </>
            ),
        },
        {
            title: "Marketer",
            dataIndex: "marketer",
            key: "marketer",
            render: (_, record) => {
                const { marketer } = record?.lead;
                const selectedMarketer = marketers[marketer];
                return (
                    <>
                        <div className="profile-box">
                            <Avatar size={40} src={selectedMarketer?.image} />
                            <div className="profile-detail-information">
                                <Text>{selectedMarketer?.title || "NA"}</Text>
                                <Tag style={{ maxWidth: "fit-content" }} color="geekblue">
                                    {selectedMarketer?.designation || "NA"}
                                </Tag>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            title: "Action",
            key: "action",
            fixed: 'right',
            // ellipsis: true,
            render: (_, record) => {
                const orderDate = moment(record.order_date).format("dddd") + " , " + moment(record.order_date).format("MMM Do YYYY");
                return (
                    <Space>
                        <Tooltip placement="bottom" title="Copy for Excel">
                            <Button onClick={()=>copyToClipboard(record)} icon={<CopyOutlined />} />
                        </Tooltip>
                        {
                            record.form
                                ?
                                <>
                                    <ViewOrderModal
                                        callBack={refetch}
                                        formId={record.form.id}
                                        orderId={record.id}
                                        upgradedOrder={record.upgraded_order}
                                        counsellor={record?.relationship_manager?.email}
                                        enrolledAt={record?.relationship_manager?.region === "3" ? 25 : 35}
                                        isApproved={record.accounts_verification}
                                        isAdmin={true}
                                        form={JSON.parse(record.form.form_response)}
                                        orderDate={orderDate} />
                                    {
                                        !record.accounts_verification
                                            ?
                                            <OrderFormModal
                                                currencies={currencies}
                                                form={record.form}
                                                mode="edit"
                                                orderId={record.id}
                                                orderDate={orderDate}
                                                lead={record.lead} />
                                            :
                                            null
                                    }
                                </>
                                :
                                null
                        }
                        <PaymentDrawer
                            disabled={!record.sale_amount}
                            payments={record.payments}
                            currencies={currencies}
                            orderId={record.id}
                            callBack={refetch}
                        />
                        {
                            userRole === "admin"
                                ?
                                <Tooltip placement="bottom" title="Activity">
                                    <Button
                                        onClick={() => setShowOrderActivty(record.id)}
                                        size="medium"
                                        icon={<ProfileOutlined />}
                                    />
                                </Tooltip>
                                :
                                null
                        }
                    </Space>
                )
            },
        },
    ];
    return (
        <>
            {showOrderActivity && userRole === "admin" ? (
                <OrderTimeline
                    orderId={showOrderActivity}
                    onClose={setShowOrderActivty}
                />
            ) : null}
            <div className="leads-action-wrapper">
                <div className="leads_action_buttons">
                    <Tooltip placement="bottom" title="Reload">
                        <Button onClick={refresh} icon={<ReloadOutlined />} />
                    </Tooltip>
                </div>
                <div className="leads-action-wrapper-right">
                    <Search
                        placeholder="input search text"
                        onSearch={onSearch}
                        allowClear
                        onPressEnter={onSearch}
                        loading={false}
                        style={{
                            maxWidth: 400,
                        }}
                    />
                    <OrderFilter />
                </div>
            </div>
            {/* <div className="d-flex gap-2 flex-wrap mt-2">
                <InfographicCard title="Total Orders" value={total} icon={<TotalBalanceIcon />} />
                <InfographicCard title="Total Payment" value="$75200" icon={<TotalOrdersIcon />} />
                <InfographicCard title="Order Successful" value="1290 " icon={<OrderSuccessfullIcon />} />
                <InfographicCard title="Rewards Point" value="1400" icon={<RewardsPointIcon />} />
            </div> */}
            <div className="lead-table">
                <Table
                    columns={filterColumns(columns)}
                    rowClassName={(record, index) =>
                        index % 2 === 0 ? "table-row-light" : "table-row-dark"
                    }
                    dataSource={records}
                    bordered={true}
                    loading={tableLoading}
                    size="small"
                    tableLayout="fixed"
                    showHeader={true}
                    // rowSelection={true}
                    pagination={{
                        position: ["none", "bottomCenter"],
                        total: total,
                        hideOnSinglePage: true,
                        pageSize: pageSize,
                        current: parseInt(currentPageNo),
                        showLessItems: true,
                        onChange: (page, pageSize) => {
                            window.scrollTo(0, 0);
                            changePage(page, pageSize);
                        },
                    }}
                    scroll={{ x: 1500 }}
                    sticky={true}
                />
            </div>
        </>
    )
}
export default OrderView;