import LogoNormal from "assets/images/logo.png";
import { Typography, Form, Input, Button, Checkbox } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import useLogin from "./useLogin";
import Image from "assets/images/artboard.png"
const { Text } = Typography;
const LoginView = () => {
    const { onSubmit, loading } = useLogin();
    return (
        <>
            <div className="loginScreenWrapper">
                <div className="loginScreenCard">
                    <div className="left_panel">
                        <Text>WELCOME</Text>
                        <img className="showOnMobile" src={LogoNormal} width={100} alt="Login - IMC Logo Normal" />
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onSubmit}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        email: true,
                                        message: "Please input your Email!",
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please input your Password!",
                                    },
                                ]}
                            >
                                <Input
                                    size="large"
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    block
                                    loading={loading}
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="right_panel">
                        <img className="w-100 h-100" src={Image} alt="Login - IMC Logo White" />
                    </div>
                </div>
            </div>
        </>
    );
};
export default LoginView;
