import axios from "./axios";
import dayjs from 'dayjs';
export const getServiceReports = (data) => {
    const { startDate, endDate } = data;
    const payload = {
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
    }
    let result = axios
        .post(`reports/service-report`, payload)
        .then((res) => {
            return res.data;
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};

export const getAdminReports = (data) => {
    const { startDate, endDate } = data;
    const payload = {
        startDate: dayjs(startDate).format('YYYY-MM-DD'),
        endDate: dayjs(endDate).format('YYYY-MM-DD'),
    }
    let result = axios
        .post(`reports/admin-report`, payload)
        .then((res) => {
            return res?.data?.data || {};
        })
        .catch((err) => {
            return err.request;
        });
    return result;
};