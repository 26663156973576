import { useState } from "react";
import { isValidEmail } from "utils";
const useClientDetails = (lead, defaultData) => {
    const { email, name, company, designation, phone } = lead;
    const { email: formEmail, name: formName, industry: formIndustry, company: formCompany, designation: formDesignation } = defaultData;
    const [newEmail, setNewEmail] = useState(formEmail || email);
    const [newName, setNewName] = useState(formName || name);
    const [whatsApp, setWhatsApp] = useState(phone);
    const [newCompany, setNewCompany] = useState(formCompany || company);
    const [newDesignation, setNewDesignation] = useState(formDesignation || designation);
    const [industry, setIndustry] = useState(formIndustry || "");
    const [error, setError] = useState("");
    const handleSubmit = (cb) => {
        const hasError = isValidEmail(newEmail);
        if (!hasError || industry === "" || newCompany === "" || newDesignation === "") {
            // const message = industry === "" ? "Please Enter Client Industry" : "Please Enter a valid email!";
            const message = "Please check the required fields!";
            setError(message);
            return;
        }
        const { country, phone } = lead;
        const clientPayload = {
            company: newCompany,
            country,
            designation: newDesignation,
            phone,
            name: newName,
            email: newEmail,
            industry,
            whatsApp
        };
        cb(clientPayload);
    }
    return {
        newEmail,
        setNewEmail,
        newName,
        setNewName,
        handleSubmit,
        error,
        industry,
        setIndustry,
        newCompany,
        setNewCompany,
        newDesignation,
        setNewDesignation,
        whatsApp,
        setWhatsApp
    }
}
export default useClientDetails;