import { Button, Form, Checkbox, Input } from 'antd';
const { TextArea } = Input;
const ServiceDetails = ({ onFinish = () => { }, defaultData = {} }) => {
    const [form] = Form.useForm();
    const { batch_no, complimentory_courses,remaarks } = defaultData;
    return (
        <>
            <Form
                form={form}
                onFinish={(values) => onFinish(values)}
                layout="vertical"
            >
                <Form.Item initialValue={complimentory_courses} label="Complimentory E-Learning Courses" name="complimentory_courses">
                    <Checkbox.Group>
                        {
                            options.map((item) => (
                                <Checkbox key={`Complimentory Courses ${item.label}`} style={{ width: "100%", marginBottom: 10 }} value={item.value}>{item.label}</Checkbox>
                            ))
                        }
                    </Checkbox.Group>
                </Form.Item>
                <Form.Item initialValue={batch_no} label="Batch No" name="batch_no">
                    <Input placeholder='Enter batch no' />
                </Form.Item>
                <Form.Item initialValue={remaarks} label="Remarks" name="remarks">
                    <TextArea  placeholder='Remarks will go here' />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" block type="primary">Save Form</Button>
                </Form.Item>
            </Form>
        </>
    )
}
export default ServiceDetails;


const options = [
    {
        label: 'Lean Six Sigma Yellow Belt',
        value: { courseId: 15, courseName: "Lean Six Sigma Yellow Belt", learningMode: { title: "E-learning", value: 1 } },
    },
    {
        label: 'ISO 9001:2015 - IMC',
        value: { courseId: 69, courseName: "ISO 9001:2015 - IMC", learningMode: { title: "E-learning", value: 1 } },
    },
    {
        label: 'CLP (Certified Lean Practitioner)',
        value: { courseId: 75, courseName: "Certified Lean Practitioner", learningMode: { title: "E-learning", value: 1 } },
    },
    {
        label: 'TQM (Total Quality Management)',
        value: { courseId: 8, courseName: "Total Quality Management", learningMode: { title: "E-learning", value: 1 } },
    },
    // {
    //     label: 'Edureka Course',
    //     value: { courseId: 8, courseName: "Total Quality Management", learningMode: { title: "E-learning", value: 1 } },
    // },
];