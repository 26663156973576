import { Cascader, List, Typography, Button } from 'antd';
import useCourseDetails from "./useCourseDetails";
import { DeleteOutlined } from '@ant-design/icons';
const { Text } = Typography;
const CoursesDetails = ({ onClick = () => { }, defaultData = [] }) => {
    const { filter, onChange, courses, removeCourse, getUniqueCourses } = useCourseDetails(defaultData);
    return (
        <>
            <Cascader
                options={getUniqueCourses()}
                onChange={onChange}
                style={{ width: "100%" }}
                placeholder="Please select"
                value=""
                showSearch={{ filter }}
                onSearch={(value) => console.log(value)}
            />
            <List
                header={<Text>Courses</Text>}
                bordered
                dataSource={courses}
                renderItem={(item, index) => (
                    <List.Item actions={[<Button onClick={() => removeCourse(index)} danger icon={<DeleteOutlined />} />]}>
                        <List.Item.Meta
                            title={item.courseName}
                            description={item.description}
                        />
                    </List.Item>
                )}
            />
            <Button
                onClick={() => onClick(courses)}
                // disabled={courses.length === 0}
                type='primary'>Next Step</Button>
        </>
    )
}
export default CoursesDetails;


