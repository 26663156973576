import { useState } from "react";
import { loginInUser } from "services";
import { App } from "antd";
const useLogin = () => {
    const { message } = App.useApp();
    const [loading, setLoading] = useState(false);
    const onSubmit = (payload) => {
        setLoading(true);
        loginInUser(payload).then((result) => {
            setLoading(false);
            if (result.status == 0) {
                message.open({
                    type: "error",
                    content: result.message,
                });
            } else if (result.status === 1) {
                const { user, token } = result.data;
                localStorage.setItem("user", JSON.stringify(user));
                localStorage.setItem("token", token);
                window.location.reload();
            }
        })
    }
    return { loading, onSubmit }
}
export default useLogin;