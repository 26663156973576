import { useState, useEffect } from "react";
import { getAllUser } from "services";
import { useSearchParams } from "react-router-dom";
import dayjs from 'dayjs';
const useServiceFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [users, setUsers] = useState([]);
    const [relationshipManagers, setRelationShipManagers] = useState([]);
    const [rangePicker, setRangePicker] = useState([]);
    const [filterApplied, setFilterApplied] = useState(0);
    const [region, setRegion] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [serviceCall, setServiceCall] = useState(null);

    useEffect(() => {
        getAllUser().then((result) => {
            if (result.status === 1) {
                setUsers(result.data);
            }
        });
    }, []);

    const handleChange = (propName, propValue) => {
        if (propName === "relationshipManagers") {
            let payload = [];
            propValue.forEach((item) => {
                const filteredUser = users.find((x) => x.f_name === item);
                payload.push(filteredUser.id);
            })
            setRelationShipManagers(payload);
        } else if (propName === "region") {
            setRegion(propValue);
        } else if (propName === "service_call") {
            setServiceCall(propValue);
        } else {
            setRangePicker(propValue);
        }
    };

    const handleOnFilterLeads = () => {
        let filter = {};
        let filterApplied = 0;
        let rm = [];
        let re = [];
        if (relationshipManagers.length > 0) {
            rm = relationshipManagers.join(",");
            filter.relationshipManagers = rm;
            filterApplied++;
        }
        if (region.length > 0) {
            re = region.join(",");
            filter.region = re;
            filterApplied++;
        }
        if (rangePicker.length > 0) {
            if (rangePicker[0] !== "" && rangePicker[1] !== "") {
                filter.range = rangePicker.map((item)=>dayjs(item).format("YYYY-MM-DD")).join(",");
                filterApplied++;
            }
        }
        console.log("rangePicker",filter.range);
        // filters: {"range":"2024-08-18,2024-08-19"}
        filter.serviceCall =serviceCall;
        setFilterApplied(filterApplied);
        const stringifyFilter = JSON.stringify(filter);
        searchParams.set("filters", stringifyFilter);
        setSearchParams(searchParams);
        setIsOpen(!isOpen);
    };
    const handleRemoveFilter = () => {
        searchParams.set("filters", JSON.stringify({}));
        setSearchParams(searchParams);
        setFilterApplied(0);
        setIsOpen(false);
    }
    return {
        users,
        handleChange,
        handleOnFilterLeads,
        handleRemoveFilter,
        isOpen,
        setIsOpen,
        filterApplied
    }
}

export default useServiceFilter;