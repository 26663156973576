import { List, Typography, Badge, Flex } from 'antd';
import { FacebookOutlined, LinkedinOutlined, SketchOutlined, ChromeOutlined, WhatsAppOutlined, InstagramOutlined, GoogleOutlined, MailOutlined, SendOutlined, SolutionOutlined } from '@ant-design/icons';
const { Text } = Typography;
const SaleBySource = ({ saleBySource = [] }) => {
    return (
        <List
            size="small"
            bordered={false}
            dataSource={saleBySource}
            renderItem={(item, index) =>
                <List.Item style={{ padding: 7, display: "flex" }}>
                    {/* <Text>{item.lead_source || "NA"}</Text> */}
                    {getSourceName(item.lead_source)}
                    <Badge overflowCount={999999} count={item?.count || 0} showZero />
                </List.Item>
            }
        />
    )
}
export default SaleBySource;

const getSourceName = (source) => {
    debugger;
    let payload;
    switch (source) {
        case "web lead":
            payload = {
                name: "Web Lead",
                icon: <ChromeOutlined />
            }
            break;

        case "web chat":
            payload = {
                name: "Web Chat",
                icon: <SendOutlined />
            }
            break;

        case "whatsapp":
            payload = {
                name: "Whatsapp",
                icon: <WhatsAppOutlined />
            }
            break;

        case "LinkedIn":
            payload = {
                name: "Linkedin",
                icon: <LinkedinOutlined />
            }
            break;

        case "fb":
            payload = {
                name: "Facebook",
                icon: <FacebookOutlined />
            }
            break;

        case "ig":
            payload = {
                name: "Instagram",
                icon: <InstagramOutlined />
            }
            break;

        case "google":
            payload = {
                name: "Google",
                icon: <GoogleOutlined />
            }
            break;

        case "email":
            payload = {
                name: "Email",
                icon: <MailOutlined />
            }
            break;

        case "reference":
            payload = {
                name: "Reference",
                icon: <SketchOutlined />
            }
            break;

        case "Contact Form":
            payload = {
                name: "Contact Form",
                icon: <SketchOutlined />
            }
            break;

        default:
            payload = {
                name: "NA",
                icon: <SolutionOutlined />
            }
            break;

    }

    return (
        <Flex gap="middle" vertical={false}>
            {payload.icon}
            <Text>{payload.name}</Text>
        </Flex>
    )
}

