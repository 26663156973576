import { useState, useEffect } from "react";
import { getServiceUserOrders } from "services";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { App } from "antd";
const useServiceOrderView = () => {
    const { message } = App.useApp();
    const [searchParams, setSearchParams] = useSearchParams();
    const [serviceOrienModal, setServiceOrienModal] = useState(false);
    useEffect(() => {
        const hasFilter = searchParams.get("filters");
        if (hasFilter) {
            setParams({ ...params, filters: hasFilter, page: searchParams.get("page") });
        }
    }, [searchParams])
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
        limit: searchParams.get("limit") || 15,
        search: searchParams.get("search") || "",
        filters: searchParams.get("filters") || "{}",
        total: 0,
    });
    const { isLoading, isError, data, error, refetch } = useQuery({
        queryKey: ["/order-service-users-view", params],
        queryFn: () => getServiceUserOrders(params),
        keepPreviousData: false,
    });
    const handleSearchParams = (key, value) => {
        searchParams.set(key, value);
        setSearchParams(searchParams);
    };
    const changePage = (page, limit) => {
        setParams({ ...params, page: page, limit: limit });
        handleSearchParams("page", page);
        handleSearchParams("limit", limit);

    };
    const onSearch = (e) => {
        const searchValue = e === "" ? "" : e.target.value;
        handleSearchParams("search", searchValue);
        handleSearchParams("page", 1);
        setParams({ ...params, search: searchValue, page: 1 });
    };
    const refreshData = () => {
        searchParams.set("page", 1);
        searchParams.set("limit", 10);
        searchParams.set("search", "");
        searchParams.set("filters", "{}");
        setSearchParams(searchParams);
        setParams({
            page: 1,
            limit: 10,
            search: "",
            filters: "{}",
            total: 0
        })
    }
    const copyToClipboard = (record) => {
        const date = moment(record.order_date).format("L");
        const arrayToCopy = [];
        arrayToCopy.push("");
        arrayToCopy.push(moment(record.order_date).format("MMMM"));
        arrayToCopy.push(date);
        arrayToCopy.push(record?.lead?.name);
        arrayToCopy.push(record?.lead?.email);
        arrayToCopy.push(record?.lead?.phone);
        arrayToCopy.push(record?.lead?.company);
        arrayToCopy.push(record?.lead?.designation);
        arrayToCopy.push("NA");
        arrayToCopy.push(record?.lead?.country);
        arrayToCopy.push(record?.lead?.industry);
        const rowText = arrayToCopy.join('\t');
        navigator.clipboard.writeText(rowText).then(() => {
            message.open({ type: "success", content: "Row copied to clipboard!" });
        }).catch(err => {
            console.error('Could not copy text: ', err);
        });
    }
    return {
        refreshData,
        refetch,
        records: data?.data?.data || [],
        total: data?.data?.total,
        tableLoading: isLoading,
        currentPageNo: params.page || 1,
        changePage,
        onSearch,
        searchValue: params.search || "",
        pageSize: params.limit || 15,
        setServiceOrienModal,
        serviceOrienModal,
        copyToClipboard
    }
}
export default useServiceOrderView;