import { Button, Modal, Select, Space } from 'antd';
import { SwapOutlined } from "@ant-design/icons";
import useChangeApplicationStatus from "./useChangeApplicationStatus";
import { pmp_application_status_enum } from "enums";
const ChangeApplicationStatus = ({ defaultValue }) => {
    const { modal, setModal } = useChangeApplicationStatus();
    return (
        <>
            <Button onClick={() => setModal(true)} size="small" icon={<SwapOutlined />} />
            {
                modal
                    ?
                    <Modal
                        title="Change Application Status"
                        centered
                        open={modal}
                        footer={null}
                        onCancel={() => setModal(false)}
                        width={300}
                    >
                        <Space size={10} direction="vertical" style={{ width: "100%" }}>
                            <Select
                                defaultValue={defaultValue}
                                style={{ width: "100%" }}
                                // onChange={}
                                options={Object.values(pmp_application_status_enum)}
                            />
                            <Button block type='primary'>Change Status</Button>
                        </Space>
                    </Modal>
                    :
                    null
            }
        </>
    )
}
export default ChangeApplicationStatus;