import { useState } from "react";
import { useSearchParams } from "react-router-dom";
const useSaleFilter = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isOpen, setIsOpen] = useState(false);
    const [filterApplied, setFilterApplied] = useState(false);
    const [rangePicker, setRangePicker] = useState([]);
    const handleRangeChange = (value, ranges) => {
        setRangePicker(ranges);
    };
    const applyFilter = () => {
        let filter = {};
        if (rangePicker.length > 0) {
            if (rangePicker[0] !== "" && rangePicker[1] !== "") {
                filter.range = rangePicker.join(",");
            }
        }
        searchParams.set("filters", JSON.stringify(filter));
        setSearchParams(searchParams);
        setFilterApplied(true);
        setIsOpen(false);
    }
    const handleRemoveFilter=()=>{
        setRangePicker([]);
        searchParams.set("filters", JSON.stringify({}));
        setSearchParams(searchParams);
        setFilterApplied(false);
        setIsOpen(false);
    }
    return {
        isOpen,
        setIsOpen,
        handleRangeChange,
        applyFilter,
        filterApplied,
        disabled: rangePicker.length === 0,
        handleRemoveFilter
    }
}
export default useSaleFilter;