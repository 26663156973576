import { useState } from "react";
import { approveOrder, sendCredientialsEmail } from "services";
const useViewOrder = () => {
    const [progress, setProgress] = useState(null);
    const [modal, setModal] = useState(false);
    const [emailPreview, setEmailPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const simulateProgress = () => {
        let interval;
        if (loading) {
            interval = setInterval(() => {
                setProgress((prev) => {
                    const nextProgress = prev + 5; // Increase progress by 5
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return nextProgress;
                });
            }, 1000); // Update progress every second
        }
    };
    const handleApproveOrder = (model, callBack) => {
        setLoading(true);
        simulateProgress();
        const payload = {
            form_id: model.formId,
            order_id: model.orderId,
            enrolledAt: model.enrolledAt,
            counsellor: model.counsellor
        };
        debugger;
        approveOrder(payload).then((result) => {
            debugger;
            // console.log("result", result);
            if (result.data) {
                sendCredientialsEmail({ userId: result.data, orderId: payload.order_id,formId:model.formId });
            }
            setProgress(100);
            callBack();
            // setModal(false);
        }).finally(() => {
            setLoading(false);
        })
    }
    return {
        modal,
        setModal,
        emailPreview,
        setEmailPreview,
        progress,
        handleApproveOrder
    }
}
export default useViewOrder;

