import { Button, Avatar, Dropdown } from "antd";
import LogoNormal from "assets/images/logo.png";
import {
  MenuOutlined,
  AppstoreAddOutlined,
  ContainerOutlined,
  BellOutlined,
  LogoutOutlined,
  FileSearchOutlined
} from "@ant-design/icons";
// import useLoginViewLogic from "business-logic/login-view-logic";
// import { useNavigateInApp } from "hooks";
const Header = () => {
//   const { userSignOut, userInfo } = useLoginViewLogic();
//   const navigate = useNavigateInApp();
  const items = [
    {
      key: "0",
      label: (
        <p onClick={() => handleRouteChange("/")}>
          <AppstoreAddOutlined /> Dashboard
        </p>
      ),
    },
    {
      key: "1",
      label: (
        <p onClick={() => handleRouteChange("/leads")}>
          <ContainerOutlined /> Leads
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p onClick={() => handleRouteChange("/files")}>
          <FileSearchOutlined /> Files
        </p>
      ),
    },
    {
      key: "5",
      label: (
        <p>
          <BellOutlined /> Notification
        </p>
      ),
    },
    {
        key: "6",
        label: (
          <p onClick={() => handleRouteChange("/logout")}>
            <LogoutOutlined /> Log Out
          </p>
        ),
      },
  ];

  const handleRouteChange = (path) => {
    // if (path === "/logout") {
    //   userSignOut();
    //   navigate("/");
    // } else {
    //   navigate(path);
    // }
  };

  return (
    <>
      <header className="header">
        <div
        //   onClick={() => handleRouteChange("/")}
          className="logo-container cursor-pointer"
        >
          <img
            alt="IMC LOGO LEFT ACTION BAR"
            className="image-cover"
            src={LogoNormal}
            // src="https://leads.imclearning.com/lm/style/logo-dark.png"
          />
        </div>
        <div className="left-container">
          <Dropdown
            menu={{
              items,
            }}
            placement="bottomRight"
            arrow
          >
            <Button type="default" placement="right" icon={<MenuOutlined />} />
          </Dropdown>
          <div
            // onClick={() => handleRouteChange("/settings")}
            className="cursor-pointer"
          >
            <Avatar
              style={{
                backgroundColor: "#1677ff",
                verticalAlign: "middle",
              }}
              size="medium"
            >
              BA{/* {userInfo?.userData?.f_name?.charAt(0)} */}
            </Avatar>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
