import { Col, Row, Card, Spin, Badge, Button, DatePicker, Collapse } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import useAdminReports from "./useAdminReports";
import dayjs from 'dayjs';
import SaleBySource from "./sale-by-source";
import BreakdownByRegion from "./breakdown-by-region";
import CostByCountry from "./cost-by-country";
const { RangePicker } = DatePicker;
const AdminReports = () => {
    const { refresh, dates, handleDatesChange, loading, reports } = useAdminReports();
    const { startDate, endDate } = dates;
    const { sales_by_source, breakdown_by_region } = reports;
    return (
        <>
            <div className="leads-action-wrapper mb-2">
                <div className="leads_action_buttons">
                    <Button onClick={refresh} icon={<ReloadOutlined />} />
                </div>
                <div className="leads-action-wrapper-right">
                    <RangePicker onChange={handleDatesChange} value={[startDate, endDate]} />
                </div>
            </div>
            <Row gutter={[20, 20]}>
                {/* <Col xs={24} sm={24} md={12} lg={6} xl={3}>
                    <Flex gap={20} vertical>
                        <Card>
                            <Statistic
                                title="Enrolled Clients"
                                value={totalEnrolledUsers}
                                valueStyle={{
                                    color: '#3f8600',
                                }}
                                prefix={<UsergroupAddOutlined />}
                            />
                        </Card>
                    </Flex>
                </Col> */}
                <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                    <Badge.Ribbon style={{ marginTop: 10 }} color='#438eff' text={`From ${dayjs(startDate).format("Do MMM YY")} to ${dayjs(endDate).format("Do MMM YY")}`}>
                        <Card title="Sales Reports">
                            <Spin spinning={loading}>
                                <Collapse
                                    size="small"
                                    defaultActiveKey={1}
                                    items={[
                                        {
                                            key: '1',
                                            label: 'Breakdown by region',
                                            children: <BreakdownByRegion data={loading ? [] : breakdown_by_region} />
                                        },
                                        {
                                            key: '2',
                                            label: 'Sales by lead source',
                                            children: <SaleBySource saleBySource={loading ? [] : sales_by_source} />,
                                        },
                                        {
                                            key: '4',
                                            label: 'Marketing cost against counsellor',
                                            children: <p>Hello</p>,
                                        },
                                    ]}
                                />
                            </Spin>
                        </Card>
                    </Badge.Ribbon>
                </Col>
                <Col xs={24} sm={24} md={20} lg={12} xl={12}>
                    <Badge.Ribbon style={{ marginTop: 10 }} color='orange' text={`Total Certificate Released`}>
                        <Card title="Product Reports">
                            <Spin spinning={loading}>
                                {/* <List
                                    size="small"
                                    bordered={false}
                                    dataSource={certificatesIssuedMonthly}
                                    renderItem={(item, index) =>
                                        <List.Item style={{ padding: 7, display: "flex" }}>
                                            <Text>{item.month}</Text>
                                            <Badge overflowCount={999999} count={item?.total_certificates || 0} showZero color={badge_colors[index] || "#faad14"} />
                                        </List.Item>
                                    }
                                /> */}
                            </Spin>
                        </Card>
                    </Badge.Ribbon>
                </Col>
            </Row>
        </>
    )
}
export default AdminReports;