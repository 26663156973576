import { Button, Form, Input,InputNumber,Modal } from 'antd';

import useAddCurrency from "./useAddCurrency";
import { EditOutlined } from '@ant-design/icons';
const AddCurrencyModal = ({ mode = "add", currency = null, callBack = () => { } }) => {
    const { modal, setModal, form, onFinish } = useAddCurrency();
    return (
        <>
            {
                mode === "add"
                    ?
                    <Button type="primary" onClick={() => setModal(true)}>
                        Add Currency
                    </Button>
                    :
                    <Button type="dashed" icon={<EditOutlined />} onClick={() => setModal(true)} />
            }
            {
                modal
                    ?
                    <Modal
                        title={`${mode === "add" ? "Add" : "Edit"} Currency`}
                        centered
                        open={modal}
                        footer={null}
                        onCancel={() => setModal(false)}
                        width={400}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            onFinish={(values) => onFinish(currency, values, callBack)}
                        >
                            <Form.Item
                                initialValue={currency?.name}
                                rules={[{ required: true, message: 'Please enter currency name!', },]}
                                label="Currency Name"
                                name="name"
                                required
                                tooltip="This is a required field">
                                <Input allowClear placeholder="Enter Currency Name" />
                            </Form.Item>
                            <Form.Item
                                initialValue={currency?.conversion_rate}
                                rules={[{ required: true, message: 'Please enter conversion rate', }]}
                                label="Conversion Rate Against AED"
                                name="conversion_rate"
                                required
                                tooltip="This is a required field">
                                <InputNumber min={0.1} max={1000} style={{width:"100%"}} placeholder="Enter Conversion Rate Againast AED" />
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType="submit" block type="primary">{`${mode === "add" ? "Save" : "Edit"} Currency`}</Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                    :
                    null
            }
        </>
    )
}

export default AddCurrencyModal;